/* eslint-disable no-prototype-builtins */
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import HaveAccess from '../../../Common/HaveAccess';
import MainPopup from '../../../Common/ModalUI/MainPopup';
import { useDispatch, useSelector } from 'react-redux';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import Confirm from '../../../Common/ModalUI/AlertModals/Confirm';
import { IRSUBModel, setSoldOff, transfer, xmlfeedaction } from '~/src/store/realtySuburban/realtySuburbanSlice';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';
import classNames from 'classnames';
import {
    setSold,
    remove,
    favorite,
    fresh,
} from '~/src/store/realtySuburban/realtySuburbanSlice';
import { selectAuth } from '~/src/store/authSlice';
import * as APConstants from '~/src/constants/agency.permission';
import {
    selectMegaSale,
} from '~/src/store/megasaleSlice';
import IListPriceRise from './IListPriceRise';
import SUBListPhotos from './RSUBListPhotos';
import IListUpdateInfo from './IListUpdateInfo';
import DaysOrHoursForRemove from './DaysOrHoursForRemove';
import ButtonWithConfirm from '../../../Common/ModalUI/ButtonWithConfirm';
import {
    fieldsHouse,
    fieldsLand,
} from '~/src/common/data/realtySuburban/realty.suburban.translate';
import ButtonWithTransferControl, { IItem as IItemTransfer } from '../../../Common/Button/ButtonWithTransferControl';
import ButtonWithXmlFeedRealtyControl, { IItem as IItemXmlFeed } from '../../CommonRealty/ButtonWithXmlFeedRealtyControl';
import InfoXmlFeed from '../../CommonRealty/InfoXmlFeed';
import EncumbranceUnderstatementNeed from '../../CommonRealty/EncumbranceUnderstatementNeed';
import ButtonWithAddMegaSale from '~/src/components/Sale/ButtonWithAddMegaSale';

const IList: FC<TListProps> = ({ item }) => {
    const [field, setFied] = useState([]);

    const dispatch = useDispatch();
    const [showOnMap, setShowOnMap] = useState(false);
    const [soldConfirmShow, setSoldConfirmShow] = useState(false);
    const [soldOffConfirmShow, setSoldOffConfirmShow] = useState(false);
    const [deleteConfirmShow, setDeleteConfirmShow] = useState(false);
    const { me } = useSelector(selectAuth);
    const { megasaleActive } = useSelector(selectMegaSale);
    const handleClickAddress = () => setShowOnMap(true);

    const fullfiedStatus = (item: IRSUBModel, obj) => {
        const result = [];
        obj.forEach((field) => {
            const value = item[field.name];
            if (value === null || (Array.isArray(value) && value.length < 3)) {
                result.push({ translate: field.translate, status: 'Нет' });
            } else {
                result.push({ translate: field.translate, status: 'Да' });
            }
        });
        return result;
    };

    useEffect(() => {
        if (item.type == 'land') {
            setFied(fullfiedStatus(item, fieldsLand));
        } else {
            setFied(fullfiedStatus(item, fieldsHouse));
        }
    }, [item]);

    const viewPriceHistoryColor = (price, priceCompare) => {
        if (Number(price) > Number(priceCompare)) {
            return 'red';
        } else {
            return 'green';
        }
    };
    const setMap = (map) => {
        const resizeObserver = new ResizeObserver(() => {
            map.invalidateSize();
        });

        const container = document.getElementById(`map-container-${item.id}`);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        resizeObserver.observe(container!);
    };

    const handleSoldClick = () => {
        dispatch(setSold(item.id));
        setSoldConfirmShow(false);
    };
    const handleSoldOffClick = () => {
        dispatch(setSoldOff(item.id));
        setSoldOffConfirmShow(false);
    };

    const handleFreshClick = () => {
        dispatch(fresh(item.id));
    };

    const handleFavoriteClick = () => {
        dispatch(favorite(item.id));
    };

    const handleDeleteClick = () => {
        dispatch(remove(item.id));
        setDeleteConfirmShow(false);
    };

    const presentationLink = `${process.env.REACT_APP_BASE_API_URL}/realty/suburban/presentation/${item.id}?agent=${me.id}`;

    const copyPresentationLink = async () => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(presentationLink);
        }
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <Link to={`/realty-suburban/card/${item.id}`}>{item.id}</Link>
                    {item.verified && (
                        <React.Fragment>
                            <br />
                            <FontAwesomeIcon
                                icon={['fas', 'check-circle']}
                                style={{ color: 'blue' }}
                                title='Подтвержденный объект'
                            />
                        </React.Fragment>
                    )}
                    <br />
                    {!item.isFullfied && (
                        <React.Fragment>
                            <FontAwesomeIcon
                                icon={['fas', 'exclamation-triangle']}
                                title={field
                                    ?.map((field) => `${field.translate}: ${field.status}`)
                                    .join('\n')}
                            />
                        </React.Fragment>
                    )}
                    {item.xmlfeedunload && item.xmlfeedunload.length > 0 &&
                        <InfoXmlFeed xmlfeedunload={item.xmlfeedunload} />
                    }
                    {item.isExclusive && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>Экс.</Badge>
                        </React.Fragment>
                    )}
                    {item.fromLegalEntity && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>От юр. лица</Badge>
                        </React.Fragment>
                    )}
                    {item.isDiscount && <React.Fragment>
                        <br />
                        <Badge className='bg-success'>Промо</Badge>
                    </React.Fragment>
                    }
                    {item.ruralMortgage && <React.Fragment>
                        <br />
                        <Badge className='bg-success'>Ипотека с ГП</Badge>
                    </React.Fragment>
                    }
                </td>
                <td>
                    <SUBListPhotos photos={item.photos} />
                    <p>
                        Внесен: {dateFormat(item.createdAt, 'dd.mm.yyyy')}
                        <br />
                        <IListUpdateInfo item={item} />
                        {/* <PriceChangedAgo {...item.priceChangedAgo} /> */}
                        <DaysOrHoursForRemove {...item} />
                        {
                            item?.freshAgo <= 5 && (
                                <React.Fragment>
                                    <br />
                                    <Badge className='bg-danger'>Скоро устареет</Badge>
                                </React.Fragment>
                            )
                        }
                    </p>
                    {item.sold && <Badge bg='danger'>В архиве</Badge>}
                    {item?.videoUrl &&
                        <Link
                            to={item?.videoUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='btn btn-sm btn-success'
                            title='Видео'
                        >
                            <FontAwesomeIcon icon={['fas', 'video']} />{' '}
                            Видео
                        </Link>
                    }
                </td>
                <td>
                    <p>
                        <strong>
                            <FormattedMessage
                                id={`realty_suburban_type_${item?.type}`}
                            />
                        </strong>
                    </p>
                    <p>
                        <strong>
                            <FormattedMessage
                                id={`realty_suburban_landAppointment_${item?.landAppointment}`}
                            />
                        </strong>
                    </p>
                    <p>
                        <strong>Адрес</strong>
                        <br />
                        <Button
                            size='sm'
                            type='button'
                            variant='link'
                            onClick={handleClickAddress}
                        >
                            {item.addressDetail?.formattedAddress}
                        </Button>
                    </p>
                    {item.cottageVillage && (
                        <p>
                            <strong>Коттеджный поселок</strong>
                            <br />
                            <span>{item?.cottageVillage?.title}</span>
                        </p>
                    )}
                    {item?.direction && (
                        <p>
                            <strong>Тракт: </strong>
                            <span>{item?.direction?.title}</span>
                        </p>
                    )}
                    {item?.cn && (
                        <p>
                            <strong>Кадастровый номер:</strong>
                            <br />
                            {item.cn}
                        </p>
                    )}
                </td>
                <td>
                    {item.area && (
                        <p>
                            <strong>Площадь участка: </strong>
                            {item.area}
                        </p>
                    )}
                    {item.houseSpaceTotal && (
                        <p>
                            <strong>Площадь дома: </strong>
                            {item.houseSpaceTotal}
                        </p>
                    )}
                    {item.gas && (
                        <p>
                            <strong>Газ</strong>
                        </p>
                    )}
                    {item.electricity && (
                        <p>
                            <strong>Электричество</strong>
                        </p>
                    )}
                    {item.waterSuply && (
                        <p>
                            <strong>Водоснабжение</strong>
                            <br></br>
                            <span>
                                <FormattedMessage
                                    id={`realty_suburban_waterSuply_${item.waterSuply}`}
                                />
                            </span>
                        </p>
                    )}
                </td>
                <td>
                    {item.houseMaterial && (
                        <p>
                            <strong>Материал дома</strong>
                            <br />
                            <FormattedMessage
                                id={`house_material_${item.houseMaterial}`}
                            />
                        </p>
                    )}
                    {item.houseStoreys && (
                        <p>
                            <strong>Этажей в доме</strong>
                            <br />
                            {item.houseStoreys}
                        </p>
                    )}
                    {item.houseRooms && (
                        <p>
                            <strong>Кол-во спальных комнат:</strong>
                            {item.houseRooms}
                        </p>
                    )}
                    {item.heating && (
                        <p>
                            <strong>Отопление</strong>
                        </p>
                    )}
                    {item.renovation && (
                        <p>
                            <strong>Ремонт</strong>
                            <br />
                            <FormattedMessage
                                id={`realty_secondary_renovation_${item.renovation}`}
                            />
                        </p>
                    )}
                </td>
                <td>
                    <p>
                        {(item?.megasale && megasaleActive) ? (
                            <>
                                <strong style={{ color: 'red' }}>Цена</strong>
                                <br />
                                <span style={{ color: 'red' }}>
                                    {new Intl.NumberFormat('ru-RU').format(+item?.price)}
                                </span>
                                <br />
                                <strong style={{ color: 'green' }}>Цена для распродажи</strong>
                                <br />
                                <span style={{ color: 'green' }}>
                                    {new Intl.NumberFormat('ru-RU').format(+item?.megasale?.price)}
                                </span>
                                <br />
                                <strong>Скидка</strong>
                                <br />
                                <span>
                                    {new Intl.NumberFormat('ru-RU').format(
                                        +item?.price - +item?.megasale?.price
                                    )}
                                </span>
                                <br></br>
                                <IListPriceRise item={item} />
                            </>
                        ) : (
                            <>
                                <strong>Цена</strong>
                                <br />
                                {item.priceHistory.length > 0 ? (
                                    <>
                                        <span
                                            style={{
                                                color: viewPriceHistoryColor(
                                                    item?.priceHistory[0]?.newPrice,
                                                    item?.priceHistory[0]?.oldPrice
                                                ),
                                            }}
                                        >
                                            {new Intl.NumberFormat('ru-RU').format(+item.price)}
                                        </span>{' '}
                                        <br />
                                        <strong>Прошлая цена</strong>
                                        <br />
                                        <span
                                        >
                                            {new Intl.NumberFormat('ru-RU').format(+item?.priceHistory[0]?.oldPrice)}
                                        </span>{' '}
                                    </>
                                ) : (
                                    <>
                                        {new Intl.NumberFormat('ru-RU').format(+item.price)}{' '}
                                    </>
                                )}
                                <br />
                                <IListPriceRise item={item} />
                            </>

                        )}
                        <br></br>
                        {item.type !== 'land'
                            ? item.price &&
                            item?.area &&
                            item?.houseSpaceTotal && (
                                <>
                                    <strong>
                                        Цена за м<sup>2</sup> дома
                                    </strong>
                                    <br></br>
                                    <span>
                                        {new Intl.NumberFormat(
                                            'ru-RU',
                                        ).format(
                                            Math.floor(
                                                +item.price /
                                                +item.houseSpaceTotal,
                                            ),
                                        )}
                                    </span>
                                    <br></br>
                                </>
                            )
                            : item.price &&
                            item?.area && (
                                <>
                                    <strong>Цена за сотку участка</strong>
                                    <br></br>
                                    <span>
                                        {new Intl.NumberFormat(
                                            'ru-RU',
                                        ).format(
                                            Math.floor(
                                                +item.price / +item.area,
                                            ),
                                        )}
                                    </span>
                                </>
                            )}
                    </p>
                    <EncumbranceUnderstatementNeed item={item} />
                </td>
                <td>
                    {item.isMy ? (
                        <React.Fragment>
                            {item.sellerName && (
                                <p>
                                    <strong>Продавец</strong>
                                    <br />
                                    {item.sellerName}
                                </p>
                            )}

                            {item.sellerPhone && (
                                <p>
                                    <strong>Телефон</strong>
                                    <br />
                                    {phoneFormatter(`${item.sellerPhone}`)}
                                </p>
                            )}
                        </React.Fragment>
                    ) : (
                        item.agentInfo && (
                            <React.Fragment>
                                {item.agentInfo?.agencyInfo?.agency && (
                                    <p>
                                        <strong>Агентство</strong>
                                        <br></br>
                                        <span>
                                            {
                                                item.agentInfo?.agencyInfo
                                                    ?.agency.title
                                            }
                                        </span>
                                    </p>
                                )}
                                <p>
                                    <strong>
                                        {item?.agentInfo?.role != 'agent'
                                            ? 'Собственник'
                                            : 'Агент'}
                                    </strong>
                                    <br />
                                    {item.agentInfo?.fullname}
                                </p>

                                <p>
                                    <strong>Телефон</strong>
                                    <br />
                                    {phoneFormatter(`${item.agentInfo?.phone}`)}
                                </p>
                            </React.Fragment>
                        )
                    )}
                </td>
                <td>
                    <ButtonGroup vertical={true} className='w-100'>
                        <Link
                            to={`/realty-suburban/card/${item.id}`}
                            className='btn btn-sm btn-secondary'
                            title='Карточка'
                        >
                            Карточка
                        </Link>
                        <HaveAccess
                            access='realty.secondary.all'
                            agencyId={item.agentInfo?.agencyInfo?.agency?.id}
                            groupId={item.agentInfo?.agencyInfo?.group?.id}
                            agencyAccess={
                                APConstants.REALTY_SECONDARY_AGENCY_UPDATE
                            }
                            groupAccess={
                                APConstants.REALTY_SECONDARY_GROUP_UPDATE
                            }
                            me={item.agentInfo?.uid}
                            roles='grand'
                        >
                            <Link
                                to={`/realty-suburban/edit/${item.id}`}
                                className='btn btn-sm btn-warning'
                                title='Редактировать'
                            >
                                Редактировать
                            </Link>
                        </HaveAccess>
                        <HaveAccess
                            access='realty.secondary.all'
                            me={item.agentInfo?.uid}
                            roles='grand'
                        >
                            <ButtonWithConfirm
                                label='Продлить'
                                modalText={`Продлить ${item.id}?`}
                                onClick={handleFreshClick}
                                variant='info'
                            />
                        </HaveAccess>
                        {item.isFavorite ? (
                            <Button
                                size='sm'
                                variant='primary'
                                title='Убрать из избранного'
                                onClick={handleFavoriteClick}
                            >
                                <FontAwesomeIcon icon={['fas', 'heart']} />{' '}
                                Убрать из избранного
                            </Button>
                        ) : (
                            <Button
                                size='sm'
                                variant='outline-primary'
                                title='Добавить в избранное'
                                onClick={handleFavoriteClick}
                            >
                                <FontAwesomeIcon icon={['fas', 'heart']} />{' '}
                                Добавить в избранное
                            </Button>
                        )}
                        {/* <Dropdown className='w-100'>
                            <Dropdown.Toggle className='w-100' variant='secondary' size='sm'>
                                Презентация
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href={presentationLink} target='_blank'>Смотреть</Dropdown.Item>
                                <Dropdown.Item onClick={copyPresentationLink}>Скопировать ссылку</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                        <HaveAccess
                            access='realty.secondary.all'
                            agencyId={item.agentInfo?.agencyInfo?.agency?.id}
                            groupId={item.agentInfo?.agencyInfo?.group?.id}
                            agencyAccess={
                                APConstants.REALTY_SECONDARY_AGENCY_UPDATE
                            }
                            groupAccess={
                                APConstants.REALTY_SECONDARY_GROUP_UPDATE
                            }
                            me={item.agentInfo?.uid}
                            roles='grand'
                        >
                            {item.sold === false && (
                                <Button
                                    type='button'
                                    size='sm'
                                    variant='danger'
                                    onClick={() => setSoldConfirmShow(true)}
                                >
                                    В архив
                                </Button>
                            )}
                        </HaveAccess>
                        <HaveAccess
                            agencyId={item.agentInfo?.agencyInfo?.agency?.id}
                            groupId={item.agentInfo?.agencyInfo?.group?.id}
                            agencyAccess={
                                APConstants.REALTY_SECONDARY_AGENCY_UPDATE
                            }
                            groupAccess={
                                APConstants.REALTY_SECONDARY_GROUP_UPDATE
                            }
                            me={item.agentInfo?.uid}
                            roles='grand'
                        >
                            {item.sold === true && (
                                <Button
                                    type='button'
                                    size='sm'
                                    variant='success'
                                    onClick={() => setSoldOffConfirmShow(true)}
                                >
                                    Вернуть в продажу
                                </Button>
                            )}
                        </HaveAccess>
                        <HaveAccess access='realty.secondary.delete'>
                            <Button
                                type='button'
                                size='sm'
                                variant='danger'
                                onClick={() => setDeleteConfirmShow(true)}
                            >
                                Удалить
                            </Button>
                        </HaveAccess>
                        <HaveAccess
                            access='xml.feed.realty.add'
                            agencyId={item.agentInfo?.agencyInfo?.agency?.id}
                            agencyAccess={APConstants.XML_FEED_REALTY_ADD}
                            roles='grand'
                        >
                            <ButtonWithXmlFeedRealtyControl item={item as IItemXmlFeed} xmlfeedaction={xmlfeedaction} />
                        </HaveAccess>
                        <HaveAccess
                            access='realty.secondary.transfer'
                            agencyId={item.agentInfo?.agencyInfo?.agency?.id}
                            groupId={item.agentInfo?.agencyInfo?.group?.id}
                            agencyAccess={
                                APConstants.REALTY_SECONDARY_AGENCY_TRANSFER
                            }
                            groupAccess={
                                APConstants.REALTY_SECONDARY_GROUP_TRANSFER
                            }
                            roles='grand'
                        >
                            <ButtonWithTransferControl item={item as IItemTransfer} transfer={transfer} />
                        </HaveAccess>
                        <HaveAccess
                            access='realty.secondary.all'
                            agencyId={item.agentInfo?.agencyInfo?.agency?.id}
                            groupId={item.agentInfo?.agencyInfo?.group?.id}
                            agencyAccess={
                                APConstants.REALTY_SECONDARY_AGENCY_UPDATE
                            }
                            groupAccess={
                                APConstants.REALTY_SECONDARY_GROUP_UPDATE
                            }
                            me={item.agentInfo?.uid}
                            roles='grand'
                        >
                            {
                                (megasaleActive && !item.sold && item.verified)
                                    ? <ButtonWithAddMegaSale item={item} type='suburban' />
                                    : null
                            }
                        </HaveAccess>
                    </ButtonGroup>
                </td>
            </tr>
            <MainPopup
                isOpened={showOnMap}
                onClose={() => setShowOnMap(false)}
                fullwidth={true}
            >
                <MapContainer
                    center={[
                        +item?.addressDetail?.latitude,
                        +item?.addressDetail?.longitude,
                    ]}
                    zoom={15}
                    maxZoom={18}
                    id={`map-container-${item.id}`}
                    whenCreated={setMap}
                    attributionControl={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='http://{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}'
                        subdomains={['tile0', 'tile1', 'tile2', 'tile3']}
                    />
                    <Marker
                        position={[
                            +item?.addressDetail?.latitude,
                            +item?.addressDetail?.longitude,
                        ]}
                    ></Marker>
                </MapContainer>
            </MainPopup>
            <Confirm
                text='Отправить в архив?'
                onClose={() => setSoldConfirmShow(false)}
                isOpened={soldConfirmShow}
                primaryButtonOnClick={handleSoldClick}
                secondaryButtonOnClick={() => setSoldConfirmShow(false)}
            />
            <Confirm
                text='Вернуть в продажу?'
                onClose={() => setSoldOffConfirmShow(false)}
                isOpened={soldOffConfirmShow}
                primaryButtonOnClick={handleSoldOffClick}
                secondaryButtonOnClick={() => setSoldOffConfirmShow(false)}
            />
            <Confirm
                text='Удалить? Это действие безвозвратно!'
                onClose={() => setDeleteConfirmShow(false)}
                isOpened={deleteConfirmShow}
                primaryButtonOnClick={handleDeleteClick}
                secondaryButtonOnClick={() => setDeleteConfirmShow(false)}
            />
        </React.Fragment>
    );
};

type TListProps = {
    item: IRSUBModel;
};

export default IList;
