import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InputField from '../Common/Form/InputField';
import SliderField from '../Common/Form/SliderField';
import CheckboxField from '../Common/Form/CheckboxField';
import { Field, Form, useForm } from 'react-final-form';
import { FormApi } from 'final-form';
import { Form as FromBootstrap } from 'react-bootstrap';
import { required } from '~/src/helpers/validators';

export default function UpdateAdditionalView(): JSX.Element {

    const form: FormApi = useForm();

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <InputField
                        label='Минимальный процент по ипотеке'
                        name='mortgage'
                    />
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <InputField
                                label='Комиссионное вознаграждение %'
                                name='reward'
                            />
                        </Col>
                        <Col>
                            {
                                !!form.getState().values.reward &&
                                <Field name='rewardDescr'>
                                    {({ input, meta }) => (
                                        <FromBootstrap.Control
                                            {...input}
                                            onChange={(e) =>
                                                form.mutators.setValueRewardDescr(
                                                    'rewardDescr',
                                                    e.target.value,
                                                )
                                            }
                                            as='textarea'
                                            rows={3}
                                            size='sm'
                                            isInvalid={
                                                meta.error || meta.submitError
                                            }
                                        />
                                    )}
                                </Field>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SliderField
                        name='popularity'
                        label='Популярность'
                        min={0}
                        max={100}
                        step={5}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5>Ключевые приемущества</h5>
                    {/* <CheckboxField
                        name='isClosedArea'
                        label='Закрытая территория'
                    /> */}
                    <CheckboxField
                        name='isRennovationFlats'
                        label='Квартиры с ремонтом'
                    />
                    {
                        !!form.getState().values.isRennovationFlats &&
                        <Field name='rennovationFlatsDescr' validate={required}>
                            {({ input, meta }) => (
                                <FromBootstrap.Control
                                    {...input}
                                    as='textarea'
                                    rows={5}
                                    size='sm'
                                    isInvalid={
                                        meta.error || meta.submitError
                                    }
                                />
                            )}
                        </Field>
                    }
                    <CheckboxField
                        name='hasUndergroundParking'
                        label='Подземная парковка'
                    />
                </Col>
                <Col>
                    <h5>Выгоды при покупке</h5>
                    <CheckboxField
                        name='installment'
                        label='Рассрочка - возможность покупки в рассрочку, без переплаты'
                    />
                    {
                        !!form.getState().values.installment &&
                        <Field name='installmentDescr' validate={required}>
                            {({ input, meta }) => (
                                <FromBootstrap.Control
                                    {...input}
                                    as='textarea'
                                    rows={5}
                                    size='sm'
                                    isInvalid={
                                        meta.error || meta.submitError
                                    }
                                />
                            )}
                        </Field>
                    }
                    <CheckboxField
                        name='buyWoInitialPay'
                        label='Покупка без первоначального взноса'
                    />
                    {
                        !!form.getState().values.buyWoInitialPay &&
                        <Field name='buyWoInitialPayDescr' validate={required}>
                            {({ input, meta }) => (
                                <FromBootstrap.Control
                                    {...input}
                                    as='textarea'
                                    rows={5}
                                    size='sm'
                                    isInvalid={
                                        meta.error || meta.submitError
                                    }
                                />
                            )}
                        </Field>
                    }
                    <CheckboxField
                        name='subsidizedRate'
                        label='Субсидированная ставка'
                    />
                    {
                        !!form.getState().values.subsidizedRate &&
                        <Field name='subsidizedRateDescr' validate={required}>
                            {({ input, meta }) => (
                                <FromBootstrap.Control
                                    {...input}
                                    as='textarea'
                                    rows={5}
                                    size='sm'
                                    isInvalid={
                                        meta.error || meta.submitError
                                    }
                                />
                            )}
                        </Field>
                    }
                    <CheckboxField
                        name='isCompanyShare'
                        label='Акции ЖК'
                    />
                    {
                        !!form.getState().values.isCompanyShare &&
                        <Field name='companyShareDescr' validate={required}>
                            {({ input, meta }) => (
                                <FromBootstrap.Control
                                    {...input}
                                    as='textarea'
                                    rows={5}
                                    size='sm'
                                    isInvalid={
                                        meta.error || meta.submitError
                                    }
                                />
                            )}
                        </Field>
                    }
                </Col>
            </Row>
            {/* <Row>
                <Col>
                    <CheckboxField name='hasCctv' label='Видеонаблюдение' />
                    <CheckboxField
                        name='isClosedArea'
                        label='Закрытая территория'
                    />
                    <CheckboxField
                        name='isProtectedArea'
                        label='Охраняемая территория'
                    />
                    <CheckboxField name='cityhouse' label='Ситихаус' />
                    <CheckboxField
                        name='lowRiseEstate'
                        label='Малоэтажный ЖК'
                    />
                    <CheckboxField name='youngFamily' label='Молодая семья' />
                    <CheckboxField
                        name='escrowAccountSell'
                        label='Продается по эскроу счету'
                    />
                    <CheckboxField
                        name='smartHouseSystem'
                        label='Система Умный дом'
                    />
                    <CheckboxField name='soundproofing' label='Шумоизоляция' />
                    <CheckboxField
                        name='isRennovationFlats'
                        label='Квартиры с ремонтом'
                    />
                    <CheckboxField
                        name='wallDecorationChoice'
                        label='Можно выбрать отделку'
                    />
                </Col>
                <Col>
                    <CheckboxField
                        name='hasPlayGround'
                        label='Игровая площадка'
                    />
                    <CheckboxField
                        name='hasSportGround'
                        label='Спортивная площадка'
                    />
                    <CheckboxField
                        name='hasPicnicGround'
                        label='Площадка для пикника'
                    />
                    <CheckboxField
                        name='hasGroundParking'
                        label='Наземная парковка'
                    />
                    <CheckboxField
                        name='hasUndergroundParking'
                        label='Подземная парковка'
                    />
                    <CheckboxField name='hasBesidePark' label='Рядом парк' />
                    <CheckboxField name='hasBesideWater' label='Рядом водоем' />
                    <CheckboxField name='hasBesideForest' label='Рядом лес' />
                    <CheckboxField name='hasBesideMall' label='Рядом ТЦ' />
                    <CheckboxField name='hasBesideSchool' label='Рядом школа' />
                    <CheckboxField
                        name='hasBesidePreSchool'
                        label='Рядом детский сад'
                    />
                </Col>
            </Row> */}
        </React.Fragment>
    );
}
