import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RSUBList from '~/src/components/Realty/Suburban';
import { useQuery } from '~/src/helpers/hooks';
import { selectAgency, unloadXmlFeeds } from '~/src/store/agencySlice';
import {
    redirect,
    setActiveMenuKey,
    setHandleScrollPosition,
} from '~/src/store/appSlice';
import { selectAuth } from '~/src/store/authSlice';
import { checkMegasale } from '~/src/store/megasaleSlice';
import { countmyoutdatedsoon } from '~/src/service/realty.suburban';
import {
    all,
    selectSUB,
    setCountMyOutDatedSoon,
} from '~/src/store/realtySuburban/realtySuburbanSlice';
import { selectDirection, unload } from '~/src/store/directionSlice';
import { selectCV, unloadCV } from '~/src/store/cottage.village/cottage.village.store';

const RealtySuburban = (): JSX.Element => {
    const { me } = useSelector(selectAuth);
    const { xmlFeeds } = useSelector(selectAgency);
    const { unloadItems } = useSelector(selectDirection);
    const { unloadCVItems } = useSelector(selectCV);

    const dispatch = useDispatch();
    const query = useQuery();

    useEffect(() => {
        dispatch(setActiveMenuKey('SU'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    useEffect(() => {
        dispatch(checkMegasale());
    }, [dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await countmyoutdatedsoon();
            dispatch(setCountMyOutDatedSoon(result));
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (unloadCVItems.length === 0) {
            dispatch(unloadCV());
        }
    }, [dispatch, unloadCVItems]);

    useEffect(() => {
        if (xmlFeeds === null && me?.agencyInfo?.agency?.id) {
            dispatch(unloadXmlFeeds(me?.agencyInfo?.agency?.id));
        }
    }, [dispatch, me?.agencyInfo?.agency?.id, xmlFeeds]);

    useEffect(() => {
        if (!query.toString().includes('own') && me) {
            me?.agencyInfo
                ? dispatch(redirect('/realty-suburban?limit=25&own=my_agency&page=1&sort=created_at_desc'))
                : dispatch(redirect('/realty-suburban?limit=25&own=all&page=1&sort=created_at_desc'));
        }
    }, [dispatch, query]);

    useEffect(() => {
        if (unloadItems.length === 0) {
            dispatch(unload());
        }
    }, [dispatch, unloadItems]);

    useEffect(() => {
        if (query.toString() !== '' && me) {
            dispatch(all(query));
        }
    }, [dispatch, query]);

    return <RSUBList></RSUBList>;
};

export default RealtySuburban;
