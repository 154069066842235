import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import ListView from '~/src/components/HousingEstate';
import { all } from '~/src/store/housingEstateSlice';
import { all as allDeveloper } from '~/src/store/developer/developerSlice';
import { unload, selectDeveloper } from '~/src/store/developer/developerSlice';
import {
    setActiveMenuKey,
    setHandleScrollPosition,
} from '~/src/store/appSlice';
import {
    selectChangePersonOnHousing,
    unloadAll,
} from '~/src/store/changePersonOnHousingSlice';

const HousingEstatePage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();
    const { unloaded } = useSelector(selectDeveloper);
    const { allHousingEstate } = useSelector(selectChangePersonOnHousing);

    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setActiveMenuKey('HE'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    useEffect(() => {
        if (allHousingEstate === null) {
            dispatch(unloadAll());
        }
    }, [dispatch, allHousingEstate]);

    useEffect(() => {
        dispatch(unload());
    }, [dispatch]);

    return <ListView />;
};

export default HousingEstatePage;
