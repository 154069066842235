import React, { useEffect } from 'react';
import { selectApp } from '~/src/store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectChangePersonOnHousing, unloadAll } from '~/src/store/changePersonOnHousingSlice';
import { all as allDeveloper, unload } from '~/src/store/developer/developerSlice';
import CreateForm from '~/src/components/DeveloperEstateFeed/create';
import { useQuery } from '~/src/helpers/hooks';

const Create = () => {
    const dispatch = useDispatch();
    const query = useQuery();

    const { allHousingEstate } = useSelector(selectChangePersonOnHousing);
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        if (allHousingEstate === null) {
            dispatch(unloadAll());
        }
    }, [dispatch, allHousingEstate]);

    useEffect(() => {
        dispatch(unload());
    }, [dispatch]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <CreateForm />}
        </React.Fragment>
    );
};

export default Create;
