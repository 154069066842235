import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import PhotoSlider from '../Common/Filestore/Photos/PhotoSlider';
import { switchEnabled } from '~/src/store/housingEstateSlice';
import { IHousingEstate } from '~/src/service/housing.estate';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import { ButtonGroup } from 'react-bootstrap';
import HaveAccess from '../Common/HaveAccess';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { FC } from 'react';
import dateFormat from 'dateformat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ListRow: FC<{ item: IHousingEstate }> = ({ item }) => {
    const dispatch = useDispatch();

    const handleSwitchEnabledClick = () => {
        dispatch(switchEnabled(item.id));
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <p>{item.id}</p>
                    {item.countSaleFlatsWithoutLayout > 0 ? (
                        <p>
                            <FontAwesomeIcon
                                icon={['fas', 'exclamation-triangle']}
                                title='Нет планировок'
                            />
                        </p>
                    ) : null}
                </td>
                <td>
                    <p>{item.title}</p>
                    <PhotoSlider photos={item?.photos || []} />
                    {item.createdAt && (
                        <p>
                            Создано:{' '}
                            {dateFormat(item.createdAt, 'dd.mm.yyyy hh:MM:ss')}
                        </p>
                    )}
                    {item.freshAt && (
                        <p>
                            Обновлен:{' '}
                            {dateFormat(item.freshAt, 'dd.mm.yyyy hh:MM:ss')}
                        </p>
                    )}
                </td>
                {/* <td>
                    {item.houses?.map((house) => {
                        return (
                            <p key={`house_${house.id}`}>
                                <span>Дом: {house.title}</span>
                                <br />
                                <span>
                                    Сдается:{' '}
                                    {`${house.year}г. ${house.quarter} кв.`}
                                </span>
                                <br />
                                <span>
                                    Создан:{' '}
                                    {dateFormat(
                                        house.createdAt,
                                        'dd.mm.yyyy hh:MM:ss',
                                    )}
                                </span>
                            </p>
                        );
                    })}
                </td> */}
                <td>
                    {item.developer.contactPhone && (
                        <p>
                            <strong>Телефон основной:</strong>
                            <br />
                            <span>
                                {phoneFormatter(item.developer.contactPhone)}
                            </span>
                        </p>
                    )}
                    {item.developer.email && (
                        <p>
                            <strong>Email:</strong>
                            <br />
                            <span>{item.developer.email}</span>
                        </p>
                    )}
                    {item.developer.contacts?.map((contact) => {
                        return (
                            <React.Fragment key={contact.id}>
                                {contact.note && <p>{contact.note}</p>}
                                {contact.phone && (
                                    <p>{phoneFormatter(`${contact.phone}`)}</p>
                                )}
                                {contact.email && <p>{contact.email}</p>}
                                <br />
                            </React.Fragment>
                        );
                    })}
                    <p>Домов: {item.housesCount}</p>
                    <p>Квартир: {item.flatsCount}</p>
                    <p>Срок сдачи ЖК: {item.endConstruction}</p>
                </td>
                <td>
                    <p>{item.mortgage && `Ипотека от ${item.mortgage} %`}</p>
                    <p>
                        {item.popularity && `Популярность ${item.popularity}`}
                    </p>
                </td>
                <td>
                    <ButtonGroup vertical className='w-100'>
                        <Link
                            to={`/housing-estate/update/${item.id}`}
                            className='btn btn-sm btn-warning'
                            title='Редактировать'
                        >
                            Редактировать
                        </Link>
                        <HaveAccess access='housing.estate.switch.enabled'>
                            <BootstrapSwitchButton
                                checked={item.isEnabled}
                                onlabel='Включен'
                                offlabel='Выключен'
                                size='sm'
                                offstyle='warning'
                                onChange={handleSwitchEnabledClick}
                            />
                        </HaveAccess>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default ListRow;
