import queryString from 'query-string';
import * as api from '~/src/service/developerEstateFeed';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../interfaces/common';
import { redirect, setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { errorNotify, successNotify } from '../helpers/createNotify';

export const sliceName = 'developerEstateFeed';

interface IDeveloperEstateFeedSlice {
    items: IResponseListData<IDEFModel>;
    item?: IDEFModel;
    search: any;
    errors?: any;
    navLink?: string;
    baseLink?: string;
}

const initialState = {
    items: {},
    item: {},
    search: {},
    navLink: '/developer-estate-feed',
    baseLink: '/developer-estate-feed',
};

const developerEstateFeedSlice: Slice<IDeveloperEstateFeedSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IDeveloperEstateFeedSlice,
            action: TAnyAction<IResponseListData<IDEFModel>>,
        ) => {
            state.items = action.payload;
        },
        set: (state: IDeveloperEstateFeedSlice, action: TAnyAction<IDEFModel>) => {
            state.item = action.payload;
        },
        setSearch: (state: IDeveloperEstateFeedSlice, action: TAnyAction<any>) => {
            state.search = action.payload;
        },
        setNavLink: (
            state: IDeveloperEstateFeedSlice,
            action: TAnyAction<string>,
        ) => {
            state.navLink = action.payload;
        },
    },
});

export const {
    setAll,
    setSearch,
    setNavLink,
    set,
} = developerEstateFeedSlice.actions;

export const goTo =
    (params: Record<string, any>) =>
        (dispatch: TAppDispatch, getState: () => TState): void => {
            if (params.reset === true) {
                params = initialState.search;
            } else params = { ...getState().developerEstate.search, ...params };
            const filteredParams = Object.fromEntries(
                Object.entries(params).filter(
                    ([_, value]) =>
                        value !== '' &&
                        (!Array.isArray(value) || value.length > 0)
                )
            );
            dispatch(
                redirect(
                    `/developer-estate-feed?${queryString.stringify(filteredParams, { skipNull: true })}`,
                ),
            );
        };

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(
            setNavLink(`${initialState.baseLink}?${queryParams.toString()}`),
        );
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const createDeveloperEstateFeed = (data: api.IDeveloperEstateCreacteFeed) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        try {
            const item = await api.createDeveloperEstateFeed(data);
            if (item) {
                successNotify({
                    message: 'Добавлено',
                    title: 'Успешно!',
                });
                dispatch(redirect(getState().developerEstateFeed.navLink));
            }
        } catch (error) {
            errorNotify({
                message: 'Нет прав',
                title: 'Ошибка!',
            });
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        try {
            const response = await api.remove(id);
            if (response) {
                successNotify({
                    message: 'Добавлено',
                    title: 'Успешно!',
                });
                dispatch(
                    setAll({
                        ...getState().developerEstateFeed.items,
                        count: getState().developerEstateFeed.items.count - 1,
                        items: getState().developerEstateFeed.items.items.filter(
                            (item: IDEFModel) => item.id != id,
                        ),
                    }),
                );
            }
        } catch (error) {
            errorNotify({
                message: '',
                title: 'Ошибка!',
            });
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (id, data: any, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        try {
            const item = await api.update(id, data);
            if (item) {
                dispatch(
                    set({
                        ...item
                    })
                );
                successNotify({
                    message: 'Запись изменена',
                    title: 'Успешно!',
                });
            }
        } catch (error) {
            errorNotify({
                message: '',
                title: 'Ошибка!',
            });
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const selectDEF = (state: TState): IDeveloperEstateFeedSlice => state[sliceName];

export default developerEstateFeedSlice.reducer;


export interface IDEFModel {
    id?: number;
    title?: string;
    description?: string;
    developerId?: number;
    housingEstateId?: number;
    fileUrl?: string;
    parsed?: IDEFModelParsed[];
    createdAt?: Date;
    updatedAt?: Date;
}

interface IDEFModelParsed {
    endAt?: Date;
    startAt?: Date;
    error?: string;
    feedId?: number;
    id?: number;
    total?: number;
}
