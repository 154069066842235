import { FormApi } from 'final-form';
import { useSelector } from 'react-redux';
import MapUpdateView from './MapUpdateView';
import { geocode } from '~/src/service/address';
import { Col, Form, Row } from 'react-bootstrap';
import InputField from '../Common/Form/InputField';
import { composeValidators, required } from '~/src/helpers/validators';
import SimpleSelect from '../Common/Form/SimpleSelect';
import { Field, FormSpy, useForm } from 'react-final-form';
import { selectDeveloper } from '~/src/store/developer/developerSlice';

export default function UpdateDescriptionView(): JSX.Element {

    const { unloaded } = useSelector(selectDeveloper);

    const developers = Array.from(new Map(unloaded.map(item => [item.id, { value: item.id.toString(), title: item.title }])).values());
    developers.unshift({ value: '', title: '' });


    const form: FormApi = useForm();

    const handleMapClick = async (lat: number, lng: number) => {
        const _res = await geocode(lat, lng);
        lat = _res[0]?.latitude || lat;
        lng = _res[0]?.longitude || lng;
        if (_res[0]?.placeId) {
            form.mutators.setValue('addressPlaceDetailId', _res[0]?.placeId);
            form.mutators.setValue('addressName', _res[0]?.address);
        }
        form.mutators.setValue('latitude', lat);
        form.mutators.setValue('longitude', lng);
    };

    return (
        <>
            <InputField name='title' label='Название' validators={required} />
            <InputField name='presentationLink' label='Ссылка на презентацию' />
            <InputField name='siteUrl' label='Ссылка на сайт застройщика' />
            <SimpleSelect
                label='Застройщик'
                name='developerId'
                opts={developers}
                validators={required}
            />
            <Form.Group>
                <a
                    href='https://autoload.avito.ru/format/New_developments.xml'
                    target='_blank'
                    rel='noreferrer'
                >
                    Искать ID здесь
                </a>
            </Form.Group>
            <InputField
                name='avitoXmlId'
                label='Avito Xml Id'
                validators={composeValidators(required)}
            />
            <InputField
                name='yandexBuildingId'
                label='Yandex Building Id'
                validators={composeValidators(required)}
            />
            <Row>
                <Col>
                    <Field name='addressPlaceDetailId'>{() => <></>}</Field>
                    <Form.Group className='mb-2'>
                        <Form.Label>Адрес</Form.Label>
                        <Field name='addressName'>
                            {({ input }) => (
                                <>
                                    <Form.Control {...input} readOnly />
                                </>
                            )}
                        </Field>
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label>Широта</Form.Label>
                        <Field name='latitude' validate={required}>
                            {({ input, meta }) => (
                                <>
                                    <Form.Control
                                        {...input}
                                        isInvalid={
                                            meta.error || meta.submitError
                                        }
                                        readOnly
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </>
                            )}
                        </Field>
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label>Долгота</Form.Label>
                        <Field name='longitude' validate={required}>
                            {({ input, meta }) => (
                                <>
                                    <Form.Control
                                        {...input}
                                        isInvalid={
                                            meta.error || meta.submitError
                                        }
                                        readOnly
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </>
                            )}
                        </Field>
                    </Form.Group>
                    <Field name='description'>
                        {({ input, meta }) => (
                            <Form.Group className='mb-3'>
                                <Form.Label>Описание</Form.Label>
                                <Form.Control
                                    {...input}
                                    as='textarea'
                                    rows={5}
                                    size='sm'
                                    isInvalid={meta.error || meta.submitError}
                                    placeholder='Описание'
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {meta.error || meta.submitError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </Field>
                </Col>
                <Col>
                    <FormSpy subscription={{ values: true }}>
                        {({ values }) => (
                            <MapUpdateView
                                onClick={handleMapClick}
                                latitude={values.latitude}
                                longitude={values.longitude}
                            />
                        )}
                    </FormSpy>
                </Col>
            </Row>
            {/* UPD:: Функционал добавления домов в жк */}
            {/* <HousingEstateHousesFormBlock /> */}
        </>
    );
}
