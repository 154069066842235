const LinkifyText = ({ text }: {
    text: string;
}) => {
    // Регулярное выражение для поиска URL
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    const parts = text.split(urlRegex);

    return (
        <p>
            {parts.map((part, index) =>
                urlRegex.test(part) ? (
                    <a
                        key={index}
                        href={part}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ color: 'blue', textDecoration: 'underline' }}
                    >
                        {part}
                    </a>
                ) : (
                    part
                ))};
        </p>
    );
};

export { LinkifyText };
