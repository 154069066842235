/* eslint-disable react/no-unescaped-entities */
import { useQuery } from '~/src/helpers/hooks';
import { redirect } from '~/src/store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BaseSyntheticEvent, useCallback } from 'react';
import Pagination from '~/src/components/Common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import SearchForm from './searchForm';
import UnloadingHousingArea from '../Unload/unloadingHousingArea';
import { selectDE, goTo, all, setSearch } from '~/src/store/developerEstate';
import ListRow from './ListRow';

const DeveloperEstateListView = (): JSX.Element => {
    const query = useQuery();

    const dispatch = useDispatch();

    const { items, search } = useSelector(selectDE);

    const sortHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ sort: e.currentTarget.value }));
        },
        [dispatch],
    );

    const limitHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ limit: e.currentTarget.value }));
        },
        [dispatch],
    );

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    return (
        <>
            <Navbar
                className='justify-content-end'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => {
                            dispatch(setSearch({}));
                            dispatch(redirect('/developer-estate'));
                        }}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Сбросить фильтр
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Row>
                <SearchForm />
                <UnloadingHousingArea />
            </Row>
            <Row className='mb-2'>
                <Col xs={4}>
                    {/* <div className='mb-2'>
                        <Form.Label></Form.Label>
                    </div> */}
                    <Form.Label>
                        <strong>Всего объектов:</strong> {items.count}
                    </Form.Label>
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col xs={4}>
                    <Form.Label>Сортировать</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.sort || 'fresh_at_desc'}
                        onChange={sortHandler}
                    >
                        <option value='id_asc'>ID &uarr;</option>
                        <option value='id_desc'>ID &darr;</option>
                        <option value='fresh_at_asc'>Обновлено &uarr;</option>
                        <option value='fresh_at_desc'>Обновлено &darr;</option>
                        <option value='popularity_asc'>
                            По популярности &uarr;
                        </option>
                        <option value='popularity_desc'>
                            По популярности &darr;
                        </option>
                    </Form.Select>
                </Col>
                <Col xs={4}>
                    <Form.Label>На странице</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.limit || 25}
                        onChange={limitHandler}
                    >
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </Form.Select>
                </Col>
            </Row>
            <Col
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                }}
            >
                {items && items.pagination?.have && (
                    <Pagination
                        pagination={items.pagination}
                        onClick={handlePaginationClick}
                    />
                )}
            </Col>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Фото</td>
                        <td>Адрес</td>
                        <td>Параметры</td>
                        <td>Дом</td>
                        <td style={{
                            minWidth: '150px'
                        }}>Цена</td>
                        <td>Продавец</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.items && items.items.length > 0
                        ? items.items.map((item) => (
                            <ListRow key={item.id} item={item} />
                        ))
                        : null}
                </tbody>
            </Table>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </>
    );
};

export default DeveloperEstateListView;
