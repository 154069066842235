import React, { BaseSyntheticEvent } from 'react';
import { ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const MultiCheckboxValue = ({
    formFilter,
    setFormFilter,
    data,
    idSelector,
    nameSelector,
    goTo,
}): JSX.Element => {
    const dispatch = useDispatch();

    const handleChangeMultiCheckboxValue = (e: BaseSyntheticEvent) => {
        const { name, value } = e.target;

        const currentValue = formFilter[name];

        let updatedValue;

        if (currentValue === undefined) {
            updatedValue = [value];
        } else if (Array.isArray(currentValue)) {
            if (currentValue.includes(value)) {
                updatedValue = currentValue.filter((item) => item !== value);
            } else {
                updatedValue = [...currentValue, value];
            }
        } else {
            updatedValue = currentValue == value ? null : [currentValue, value];
        }

        const updatedFormFilter = {
            ...formFilter,
            [name]: updatedValue,
        };

        dispatch(goTo(updatedFormFilter));
    };

    return (
        <Form.Group>
            <ButtonGroup>
                {data.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        size='sm'
                        id={`${idSelector}-${idx}`}
                        type='checkbox'
                        variant={
                            formFilter[nameSelector]?.includes(radio.value)
                                ? radio.variantOn
                                : radio.variantOff
                        }
                        name={nameSelector}
                        value={radio.value}
                        checked={formFilter[nameSelector]?.includes(
                            radio.value,
                        )}
                        onChange={handleChangeMultiCheckboxValue}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </Form.Group>
    );
};

export default MultiCheckboxValue;
