/* eslint-disable react/no-unescaped-entities */
import ListRow from './ListRow';
import { Link } from 'react-router-dom';
import QuickSearch from './QuickSearchList';
import { useQuery } from '~/src/helpers/hooks';
import { redirect } from '~/src/store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BaseSyntheticEvent, useCallback } from 'react';
import Pagination from '~/src/components/Common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectHE, goTo, all } from '~/src/store/housingEstateSlice';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import OpenChangePersonOnHousin from './changeResponsible/OpenChangePersonOnHousin';
import { selectDeveloper } from '~/src/store/developer/developerSlice';
import Select from 'react-select';

const HousingEstateListView = (): JSX.Element => {
    const query = useQuery();

    const dispatch = useDispatch();

    const { items, search } = useSelector(selectHE);
    const { unloaded } = useSelector(selectDeveloper);

    const developers = Array.from(new Map(unloaded.map(item => [item.id, { value: item.id.toString(), label: item.title }])).values());

    const handleChangeHousingDeveloper = (item) => {
        dispatch(
            goTo({
                developerId: item.value,
            }),
        );
    };
    const sortHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ sort: e.currentTarget.value }));
        },
        [dispatch],
    );

    const limitHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ limit: e.currentTarget.value }));
        },
        [dispatch],
    );

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };
    const onChangeEnable = useCallback(
        (e: BaseSyntheticEvent) => {
            const valueSelect = e.target.value;
            dispatch(
                goTo({ isEnabled: valueSelect == 'all' ? null : valueSelect }),
            );
        },
        [dispatch],
    );

    return (
        <>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Link
                        to={'/housing-estate/create/'}
                        className='btn btn-sm btn-success'
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить ЖК
                    </Link>
                    <Link
                        to={'/developer/create/'}
                        className='btn btn-sm btn-primary'
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить
                        застройщика
                    </Link>
                    <Link
                        to={'/developer'}
                        className='btn btn-sm btn-secondary'
                    >
                        <FontAwesomeIcon icon={['fas', 'drafting-compass']} />{' '}
                        Застройщики
                    </Link>
                </ButtonGroup>
                <ButtonGroup>
                    <Button size='sm' type='button' variant='outline-secondary'>
                        <FontAwesomeIcon icon={['fas', 'eye']} /> Форма поиска
                    </Button>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => dispatch(redirect('/housing-estate'))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Сбросить
                        фильтр
                    </Button>
                    <OpenChangePersonOnHousin />
                </ButtonGroup>
            </Navbar>

            <Row className='mb-2'>
                <Col xs={4}>
                    <Form.Label>
                        <>Застройщик</>
                    </Form.Label>
                    <Select
                        name='developerId'
                        options={developers}
                        placeholder='Выберите застройщика'
                        onChange={handleChangeHousingDeveloper}
                        value={developers?.find(
                            (developer) =>
                                developer?.value === search?.developerId,
                        )}
                    />
                </Col>
                <Col xs={4}></Col>
                <Col xs={4}>
                    <div className='mb-2'>
                        <Form.Label></Form.Label>
                    </div>
                    <Form.Label>
                        <strong>Всего объектов:</strong> {items.count}
                    </Form.Label>
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col xs={4}>
                    <Form.Label>Сортировать</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.sort || 'fresh_at_desc'}
                        onChange={sortHandler}
                    >
                        <option value='id_asc'>ID &uarr;</option>
                        <option value='id_desc'>ID &darr;</option>
                        <option value='fresh_at_asc'>Обновлено &uarr;</option>
                        <option value='fresh_at_desc'>Обновлено &darr;</option>
                        <option value='popularity_asc'>
                            По популярности &uarr;
                        </option>
                        <option value='popularity_desc'>
                            По популярности &darr;
                        </option>
                    </Form.Select>
                </Col>
                <Col xs={4}>
                    <Form.Label>На странице</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.limit || 25}
                        onChange={limitHandler}
                    >
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </Form.Select>
                </Col>
                <Col xs={4}>
                    <QuickSearch />
                </Col>
                <Col xs={4} className='mb-3'>
                    <Form.Group>
                        <Form.Label>Тип</Form.Label>
                        <Form.Select
                            name='isEnabled'
                            size='sm'
                            value={search?.isEnabled || 'all'}
                            onChange={onChangeEnable}
                        >
                            <option value={'all'}>Все</option>
                            <option value={1}>Включенные</option>
                            <option value={0}>Выключенные</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Col
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                }}
            >
                {items && items.pagination?.have && (
                    <Pagination
                        pagination={items.pagination}
                        onClick={handlePaginationClick}
                    />
                )}
                <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                    }}
                >
                    <a
                        href='https://docs.google.com/document/d/1Kl2Z7oIUPQhV9cGADpari-9Ghg912-46/edit#heading=h.gjdgxs'
                        rel='noreferrer'
                        target='_blank'
                    >
                        "Генерирование ЖК"
                    </a>
                    <br />
                    <a
                        href='https://docs.google.com/document/d/1CdLW9Fgd9MQv2dc9aA3E-Ke-bKmUmtNd/edit#heading=h.gjdgxs'
                        rel='noreferrer'
                        target='_blank'
                    >
                        "Регламент по актуализации ЖК"
                    </a>
                </div>
            </Col>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Название</td>
                        {/* <td>Дома</td> */}
                        <td>Информация</td>
                        <td>Параметры</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.items && items.items.length > 0
                        ? items.items.map((item) => (
                            <ListRow key={item.id} item={item} />
                        ))
                        : null}
                </tbody>
            </Table>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </>
    );
};

export default HousingEstateListView;
