import Api from '~/src/helpers/http-common';
import { IResponseListData } from '../interfaces/common';
import { IDEModel } from '../store/developerEstate';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IDEModel>> => {
    return await Api.get(
        `/housing-estate/realty/all?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const favorite = async (id: string | number): Promise<any> => {
    return await Api.post(`/housing-estate/realty/favorite/${id}`);
};
