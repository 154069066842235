import classNames from 'classnames';
import { Link } from 'react-router-dom';
import SlideDown from 'react-slidedown';
import HaveAccess from '../Common/HaveAccess';
import React, { useEffect, useState } from 'react';
import { selectApp } from '~/src/store/appSlice';
import { selectUsers } from '~/src/store/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getMe, selectAuth } from '~/src/store/authSlice';
import { selectRS } from '~/src/store/realtySecondary/realtySecondarySlice';
import { selectRSM } from '~/src/store/realtySecondary/realty.secondary.moderate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as APConstants from '~/src/constants/agency.permission';
import { selectAddress } from '~/src/store/address';
import { selectHE } from '~/src/store/housingEstateSlice';
import { selectAgencyStatistics } from '~/src/store/agency.statistics';
import { selectInfo } from '~/src/store/info.agency.users.slice';
import { Badge } from 'react-bootstrap';
import { selectBuyerList } from '~/src/store/buyer/buyer.list.slice';
import { selectSUB } from '~/src/store/realtySuburban/realtySuburbanSlice';
import { selectRSUBM } from '~/src/store/realtySuburban/realtySuburbanSlice.moderate';
import { selectDHP } from '~/src/store/developer/developer.house.project';
import { selectDeveloper } from '~/src/store/developer/developerSlice';
import { selectCV } from '~/src/store/cottage.village/cottage.village.store';
import { selectDE } from '~/src/store/developerEstate';
import { selectDEF } from '~/src/store/developerEstateFeed';

export default function LeftSideNav(): JSX.Element {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [closedAgencyDropDown, setClosedAD] = useState(true);

    const { activeMenuKey } = useSelector(selectApp);
    const { navLink: navLinkRS } = useSelector(selectRS);
    const { navLink: navLinkSUB } = useSelector(selectSUB);
    const { navLink: navLinkHE } = useSelector(selectHE);
    const { navLink: navLinkBuyer } = useSelector(selectBuyerList);
    const { navLink: navLinkSUBM } = useSelector(selectRSUBM);
    const { navLink: navLinkRSM } = useSelector(selectRSM);
    const { baseLink: navLinkCV } = useSelector(selectCV);
    const { baseLink: navLinkDE } = useSelector(selectDE);
    const { baseLink: navLinkDEF } = useSelector(selectDEF);
    const { baseLink: navLinkDHP } = useSelector(selectDHP);
    const { baseLink: navLinkDEV } = useSelector(selectDeveloper);
    const { navLink: navLinkUsers } = useSelector(selectUsers);
    const { navLink: navLinkAddress } = useSelector(selectAddress);
    const { baseLink: agencyStatisticBaseLink } = useSelector(
        selectAgencyStatistics,
    );

    const { me } = useSelector(selectAuth);

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    const toggleShow = () => {
        if (show) {
            setClosedAD(true);
        }

        setShow(!show);
    };

    const toggleAD = () => {
        if (!show && closedAgencyDropDown) {
            setShow(true);
        }
        setClosedAD(!closedAgencyDropDown);
    };

    return (
        <div>
            <div className='left-side-nav'>
                <div
                    className={classNames({
                        'menu-bar': true,
                        show: show,
                    })}
                >
                    <input
                        type='checkbox'
                        id='hmt'
                        className='hidden-menu-ticker'
                        onClick={toggleShow}
                    />
                    <label className='btn-menu' htmlFor='hmt'>
                        <span className='first'></span>
                        <span className='second'></span>
                        <span className='third'></span>
                    </label>
                    <div
                        className={classNames({
                            'hidden-menu': true,
                        })}
                    >
                        <ul className='p-0'>
                            {me && me.agencyInfo && (
                                <React.Fragment>
                                    <li onClick={toggleAD}>
                                        <span>
                                            <FontAwesomeIcon
                                                icon={['fas', 'home']}
                                            />{' '}
                                            <small>
                                                {' '}
                                                {me.agencyInfo.agency.title}
                                            </small>
                                        </span>
                                    </li>
                                    <SlideDown
                                        className={'my-dropdown-slidedown'}
                                        closed={closedAgencyDropDown}
                                        style={{ marginLeft: '34px' }}
                                    >
                                        {(me.agencyInfo.isDirector ||
                                            me.agencyInfo.permissions.includes(
                                                APConstants.AGENCY_UPDATE,
                                            )) && (
                                                <li
                                                    className={classNames({
                                                        active:
                                                            activeMenuKey ===
                                                            'AgencyProfile',
                                                    })}
                                                >
                                                    <Link to='/agency/my'>
                                                        <small>
                                                            Профиль компании
                                                        </small>
                                                    </Link>
                                                </li>
                                            )}
                                        <li
                                            className={classNames({
                                                active:
                                                    activeMenuKey ===
                                                    'AgencyAgents',
                                            })}
                                        >
                                            <Link to='/agency/my/agent'>
                                                <small>Сотрудники</small>
                                            </Link>
                                        </li>
                                        {(me.agencyInfo.isDirector ||
                                            me.agencyInfo.permissions.includes(
                                                APConstants.XML_FEED_AGENCY_UPDATE,
                                            )) && (
                                                <li
                                                    className={classNames({
                                                        active:
                                                            activeMenuKey ===
                                                            'AgencyXmlFeed',
                                                    })}
                                                >
                                                    <Link to='/agency/my/xml-feed'>
                                                        <small>Реклама</small>
                                                    </Link>
                                                </li>
                                            )}
                                        {(me.agencyInfo.isDirector ||
                                            me.agencyInfo.permissions.includes(
                                                APConstants.AGENCY_STATISTICS,
                                            )) && (
                                                <li
                                                    className={classNames({
                                                        active:
                                                            activeMenuKey ===
                                                            'AgencyStatistics',
                                                    })}
                                                >
                                                    <Link
                                                        to={agencyStatisticBaseLink}
                                                    >
                                                        <small>Статистика</small>
                                                    </Link>
                                                </li>
                                            )}
                                    </SlideDown>
                                </React.Fragment>
                            )}
                            <li
                                className={classNames({
                                    active: activeMenuKey === 'RS',
                                })}
                            >
                                <Link
                                    to={navLinkRS}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'building']}
                                    />{' '}
                                    <small>Квартиры (список)</small>
                                </Link>
                            </li>
                            <li
                                className={classNames({
                                    active: activeMenuKey === 'SU',
                                })}
                            >
                                <Link
                                    to={navLinkSUB}
                                >
                                    <FontAwesomeIcon icon={['fas', 'tree']} />{' '}
                                    <small>Загородка (список)</small>
                                </Link>
                            </li>
                            <li
                                className={classNames({
                                    active: activeMenuKey === 'DE',
                                })}
                            >
                                <Link
                                    to={navLinkDE}
                                    title='Новостройки от застройщика'
                                >
                                    <FontAwesomeIcon icon={['fas', 'hotel']} />{' '}
                                    <small>Новостройки от застройщика</small>
                                </Link>
                            </li>
                            <HaveAccess
                                access='housing.estate.feed.update'
                            >
                                <li
                                    className={classNames({
                                        active: activeMenuKey === 'DEF',
                                    })}
                                >
                                    <Link
                                        to={navLinkDEF}
                                        title='Фиды'
                                    >
                                        <FontAwesomeIcon icon={['fas', 'print']} />{' '}
                                        <small>Фиды</small>
                                    </Link>
                                </li>
                            </HaveAccess>
                            <li
                                className={classNames({
                                    active: activeMenuKey === 'Buyer',
                                })}
                            >
                                <Link to={navLinkBuyer} title='Покупатели'>
                                    <FontAwesomeIcon icon={['fas', 'wallet']} />{' '}
                                    <small>Покупатели</small>
                                </Link>
                            </li>
                            <HaveAccess access='realty.secondary.moderate'>
                                <li
                                    className={classNames({
                                        active: activeMenuKey === 'RSUBM',
                                    })}
                                >
                                    <Link
                                        to={navLinkSUBM}
                                        title='Загородная недвижимость, модерация'
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'eye']}
                                        />{' '}
                                        <small>Загородка, модерация</small>
                                    </Link>
                                </li>
                                <li
                                    className={classNames({
                                        active: activeMenuKey === 'RSM',
                                    })}
                                >
                                    <Link
                                        to={navLinkRSM}
                                        title='Квартиры, модерация'
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'glasses']}
                                        />{' '}
                                        <small>Квартиры, модерация</small>
                                    </Link>
                                </li>
                            </HaveAccess>
                            <HaveAccess access='housing.estate.list'>
                                <li
                                    className={classNames({
                                        active: activeMenuKey === 'HE',
                                    })}
                                >
                                    <Link to={navLinkHE} title='Новостройки'>
                                        <FontAwesomeIcon
                                            icon={['fas', 'archway']}
                                        />{' '}
                                        <small>Новостройки</small>
                                    </Link>
                                </li>
                            </HaveAccess>
                            <li
                                className={classNames({
                                    active: activeMenuKey === 'CV',
                                })}
                            >
                                <Link
                                    to={navLinkCV}
                                    title='Коттеджные поселки'
                                >
                                    <FontAwesomeIcon icon={['fas', 'gem']} />{' '}
                                    <small>Коттеджные поселки</small>
                                </Link>
                            </li>
                            <li
                                className={classNames({
                                    active: activeMenuKey === 'DHP',
                                })}
                            >
                                <Link
                                    to={navLinkDHP}
                                    title='Строительство домов'
                                >
                                    <FontAwesomeIcon icon={['fas', 'house-user']} />{' '}
                                    <small>Строительство домов</small>
                                </Link>
                            </li>
                            <li
                                className={classNames({
                                    active: activeMenuKey === 'DEV',
                                })}
                            >
                                <Link
                                    to={navLinkDEV}
                                    title='Партнеры'
                                >
                                    <FontAwesomeIcon icon={['fas', 'handshake']} />{' '}
                                    <small>Партнеры</small>
                                </Link>
                            </li>
                            <HaveAccess access='agency.list'>
                                <li
                                    className={classNames({
                                        active: activeMenuKey === 'AGN',
                                    })}
                                >
                                    <Link to='/agency' title='Агентства'>
                                        <FontAwesomeIcon
                                            icon={['fas', 'city']}
                                        />{' '}
                                        <small>Агентства</small>
                                    </Link>
                                </li>
                            </HaveAccess>
                            <HaveAccess access='users.read.all'>
                                <InfoAppLink />
                            </HaveAccess>
                            <HaveAccess access='megasale.create'>
                                <li
                                    className={classNames({
                                        active: activeMenuKey === 'SALE',
                                    })}
                                >
                                    <Link to='/sale' title='Распродажа'>
                                        <FontAwesomeIcon
                                            icon={['fas', 'money-bill-wave']}
                                        />{' '}
                                        <small>Распродажа</small>
                                    </Link>
                                </li>
                            </HaveAccess>
                            <HaveAccess access='*'>
                                <li
                                    className={classNames({
                                        active: activeMenuKey === 'PRM',
                                    })}
                                >
                                    <Link
                                        to='/permission'
                                        title='Права Доступа'
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'shield-alt']}
                                        />{' '}
                                        <small>Права Доступа</small>
                                    </Link>
                                </li>
                                <li
                                    className={classNames({
                                        active: activeMenuKey === 'USR',
                                    })}
                                >
                                    <Link
                                        to={navLinkUsers}
                                        title='Пользователи'
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'users']}
                                        />{' '}
                                        <small>Пользователи</small>
                                    </Link>
                                </li>
                                <li
                                    className={classNames({
                                        active: activeMenuKey === 'ADR',
                                    })}
                                >
                                    <Link to={navLinkAddress} title='Адреса'>
                                        <FontAwesomeIcon
                                            icon={['fas', 'map-marker-alt']}
                                        />{' '}
                                        <small>Адреса</small>
                                    </Link>
                                </li>
                            </HaveAccess>
                            <HaveAccess access='*'>
                                <li
                                    className={classNames({
                                        active: activeMenuKey === 'ADMT',
                                    })}
                                >
                                    <Link to='/admin-tools' title='tools'>
                                        <FontAwesomeIcon
                                            icon={['fas', 'tools']}
                                        />{' '}
                                        <small>tools</small>
                                    </Link>
                                </li>
                            </HaveAccess>
                            {me && (
                                <li
                                    className={classNames({
                                        active: activeMenuKey === 'PA',
                                    })}
                                >
                                    <Link to='/pa' title='Личный кабинет'>
                                        <FontAwesomeIcon
                                            icon={['fas', 'user-secret']}
                                        />{' '}
                                        <small>ЛК</small>
                                    </Link>
                                </li>
                            )}
                            <HaveAccess access='content.edit'>
                                <li
                                    className={classNames({
                                        active:
                                            activeMenuKey === 'AboutService',
                                    })}
                                >
                                    <Link to='/about-service' title='О Сервисе'>
                                        <FontAwesomeIcon
                                            icon={['fas', 'book-open']}
                                        />{' '}
                                        <small>О Сервисе</small>
                                    </Link>
                                </li>
                                <li
                                    className={classNames({
                                        active: activeMenuKey === 'Support',
                                    })}
                                >
                                    <Link to='/support' title='Поддержка'>
                                        <FontAwesomeIcon
                                            icon={['fas', 'hands-helping']}
                                        />{' '}
                                        <small>Поддержка</small>
                                    </Link>
                                </li>
                            </HaveAccess>
                            {/* <HaveAccess access='filestore.test'>
                                <li>
                                    <Link to='/filestore-test' title='Filestore test'>
                                        <FontAwesomeIcon icon={['fas', 'file-upload']} /> <small>Filestore</small>
                                    </Link>
                                </li>
                            </HaveAccess> */}
                            <li>
                                <Link to='/logout' title='Выйти'>
                                    <FontAwesomeIcon
                                        icon={['fas', 'sign-out-alt']}
                                    />{' '}
                                    <small>Выйти</small>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

const InfoAppLink = () => {
    const { unverifiedCount } = useSelector(selectInfo);
    const { activeMenuKey } = useSelector(selectApp);

    const color = unverifiedCount > 0 ? 'orange' : 'white';

    console.log('RENDER InfoAppLink');
    console.log(color);

    return (
        <li className={classNames({ active: activeMenuKey === 'info-agency' })}>
            <Link to='/info-agency' title='Инфо'>
                <FontAwesomeIcon icon={['fas', 'yin-yang']} style={{ color }} />
                <small>
                    {' '}
                    Инфо <Badge bg='danger'>{unverifiedCount}</Badge>
                </small>
            </Link>
        </li>
    );
};
