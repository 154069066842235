import React, {
    SyntheticEvent,
    BaseSyntheticEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Col, Form, InputGroup, Row, ToggleButton } from 'react-bootstrap';
import { goTo, selectDE, setSearch } from '~/src/store/developerEstate';
import Select, { MultiValue } from 'react-select';
import { ICombineHousingLivingAreaData, selectlivingArea } from '~/src/store/livingAreaSlice';
import { realtyRoomsBtns } from '~/src/common/data/search.form';
import Checkbox from '../Common/FormFilter/Checkbox';

const SearchForm = () => {
    const dispatch = useDispatch();
    const { search } = useSelector(selectDE);
    const { combineHousingLivingAreaData } = useSelector(selectlivingArea);

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        const filter = {};
        for (const [key, value] of Object.entries(search)) {
            if (key === 'priceMin' || key === 'priceMax') {
                filter[key] = value.toString().replaceAll(/\D/g, '');
            } else {
                filter[key] = value;
            }
        }
        filter['page'] = 1;
        dispatch(goTo(filter));
    };

    const handleChangePrice = (e: BaseSyntheticEvent) => {
        let value;
        if (e.target.value.length > 0) {
            value = e.target.value.replaceAll(/\D/g, '');
            value = new Intl.NumberFormat('ru-RU').format(value);
        } else {
            value = e.target.value;
        }
        dispatch(setSearch({ ...search, [e.target.name]: value }));
    };

    const handleChangeValueNumberValidator = (e: BaseSyntheticEvent) => {
        dispatch(setSearch({
            ...search,
            [e.target.name]: e.target.value.replaceAll(/\D/g, ''),
        }));
    };

    const handleChangeValue = (e: BaseSyntheticEvent) => {
        dispatch(
            setSearch({ ...search, [e.target.name]: e.target.value })
        );
    };

    const filterValuesHousingLivingArea = () => {
        if (!combineHousingLivingAreaData) return [];
        // привести к массиву в любом случае
        const housingEstateArray = Array.isArray(search?.housingEstateId)
            ? search.housingEstateId
            : search?.housingEstateId
                ? [String(search.housingEstateId)]
                : [];

        const livingAreaArray = Array.isArray(search?.livingArea)
            ? search.livingArea
            : search?.livingArea
                ? [search.livingArea]
                : [];

        const data = combineHousingLivingAreaData.filter(curr =>
            housingEstateArray.some(id => id === String(curr.value)) ||
            livingAreaArray.some(area => area === curr.label));

        return data;
    };

    const changeSelectSerch = (option, inputValue) => {
        const normalizedOptionLabel = option.label
            .toLowerCase()
            .replace('е', 'ё');
        const normalizedInputValue = inputValue.toLowerCase().replace('е', 'ё');
        return normalizedOptionLabel.includes(normalizedInputValue);
    };

    const handleChangeHousingLivingArea = (
        items: MultiValue<ICombineHousingLivingAreaData>,
    ) => {
        const housingValue = [];
        const livingArea = [];
        items.forEach((item, index) => {
            if (item.type === 'housing') {
                housingValue.push(String(item.value));
            } else {
                livingArea.push(item.label);
            }
        });
        dispatch(
            setSearch({
                ...search,
                housingEstateId: housingValue,
                livingArea: livingArea,
            })
        );
    };

    const handleChangeCheckboxValue = (e: BaseSyntheticEvent) => {
        dispatch(
            setSearch({
                ...search,
                [e.target.name]: search[e.target.name] == '1' ? null : '1',
            })
        );
    };

    const handleChangeCheckboxValueGo = (e: BaseSyntheticEvent) => {
        dispatch(goTo({
            [e.target.name]: search[e.target.name] == '1' ? null : '1',
        }));
    };

    const handleChangeMultiCheckboxValue = (e: BaseSyntheticEvent) => {
        const { name, value } = e.target;
        if (!Array.isArray(search[name])) {
            const updatedSearch = {
                ...search,
                [name]: [search[name], value],
            };
            dispatch(setSearch(updatedSearch));
            return;
        }
        if (search[name].includes(value)) {
            const updatedSearch = {
                ...search,
                [name]: search[name].filter((v: string) => v !== value),
            };
            dispatch(setSearch(updatedSearch));
            return;
        }
        const updatedSearch = {
            ...search,
            [name]: [...search[name], value],
        };
        dispatch(setSearch(updatedSearch));
    };

    return (
        <>
            <Form className='mt-2 bg-light p-3' onSubmit={handleSubmit}>
                <Row className='mb-3 d-flex align-items-end'>
                    <Col xs={4}>
                        <Form.Group>
                            <Form.Label>
                                <>Жилой комплекс или район</>
                            </Form.Label>
                            <Select
                                name='area'
                                isMulti
                                options={combineHousingLivingAreaData || []}
                                value={search?.housingEstateId?.length || search?.livingArea?.length ? filterValuesHousingLivingArea() : []}
                                placeholder='Выберите ЖК, район'
                                onChange={handleChangeHousingLivingArea}
                                filterOption={changeSelectSerch}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={1}>
                        <ToggleButton
                            size='sm'
                            id='favorite-spec-btn-not-first'
                            type='checkbox'
                            variant={
                                search.favorite === '1'
                                    ? 'primary'
                                    : 'outline-primary'
                            }
                            name='favorite'
                            value='1'
                            checked={search.favorite === '1'}
                            onChange={handleChangeCheckboxValueGo}
                        >
                            Избранное
                        </ToggleButton>
                    </Col>
                    <Col xs={3}>
                        <Form.Group>
                            <Form.Label>
                                <>Цена</>
                            </Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='priceMin'
                                        value={search.priceMin || ''}
                                        onChange={handleChangePrice}
                                        placeholder='от'
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='priceMax'
                                        value={search.priceMax || ''}
                                        onChange={handleChangePrice}
                                        placeholder='до'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <Form.Group>
                            <Form.Label>
                                <>Квартал </>
                            </Form.Label>
                            <Row>
                                <Col>
                                    <Form.Select
                                        name='quarterMin'
                                        size='sm'
                                        value={
                                            search?.quarterMin ||
                                            ''
                                        }
                                        onChange={handleChangeValue}
                                        placeholder='от'
                                    >
                                        {Array(5)
                                            .fill(1)
                                            .map((_, index) =>
                                                index == 0 ? (
                                                    <option
                                                        key={`houseBuiltQuarterMin-${index}`}
                                                        value=''
                                                    >
                                                        от
                                                    </option>
                                                ) : (
                                                    <option
                                                        key={`houseBuiltQuarterMin-${index}`}
                                                        value={index}
                                                    >
                                                        {index}
                                                    </option>
                                                ),)}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Select
                                        name='quarterMax'
                                        size='sm'
                                        value={
                                            search?.quarterMax ||
                                            ''
                                        }
                                        onChange={handleChangeValue}
                                        placeholder='до'
                                    >
                                        {Array(5)
                                            .fill(1)
                                            .map((_, index) =>
                                                index == 0 ? (
                                                    <option
                                                        key={`houseBuiltQuarterMin-${index}`}
                                                        value=''
                                                    >
                                                        до
                                                    </option>
                                                ) : (
                                                    <option
                                                        key={`houseBuiltQuarterMin-${index}`}
                                                        value={index}
                                                    >
                                                        {index}
                                                    </option>
                                                ),)}
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3 d-flex align-items-end'>
                    <Col xs={2}>
                        <Form.Group>
                            <Form.Label>
                                <>Комнатность</>
                            </Form.Label>
                            <ButtonGroup>
                                <ToggleButton
                                    size='sm'
                                    id={'rooms-btns-studio'}
                                    type='checkbox'
                                    variant={search['studio'] == '1' ? 'primary' : 'outline-primary'}
                                    name='studio'
                                    value={search['studio']}
                                    checked={search['studio']}
                                    onChange={handleChangeCheckboxValue}
                                >
                                    Студия
                                </ToggleButton>
                                {realtyRoomsBtns.map((radio, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        size='sm'
                                        id={`rooms-btns-${idx}`}
                                        type='checkbox'
                                        variant={
                                            search['rooms']?.includes(radio.value)
                                                ? radio.variantOn
                                                : radio.variantOff
                                        }
                                        name='rooms'
                                        value={radio.value}
                                        checked={search['rooms']?.includes(radio.value)}
                                        onChange={handleChangeMultiCheckboxValue}
                                    >
                                        {radio.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group>
                            <Form.Label>
                                <>Этаж</>
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='storeyMin'
                                    value={search?.storeyMin || ''}
                                    onChange={handleChangeValueNumberValidator}
                                />
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='storeyMax'
                                    value={search?.storeyMax || ''}
                                    onChange={handleChangeValueNumberValidator}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    {/* <Col xs={2}>
                        <ButtonGroup>
                            <ToggleButton
                                size='sm'
                                id='storey-spec-btn-not-first'
                                type='checkbox'
                                variant={
                                    search.storeyNotFirst === '1'
                                        ? 'primary'
                                        : 'outline-primary'
                                }
                                name='storeyNotFirst'
                                value='1'
                                checked={search.storeyNotFirst === '1'}
                                onChange={handleChangeCheckboxValue}
                            >
                                Не первый
                            </ToggleButton>
                            <ToggleButton
                                size='sm'
                                id='storey-spec-btn-not-last'
                                type='checkbox'
                                variant={
                                    search.storeyNotLast === '1'
                                        ? 'primary'
                                        : 'outline-primary'
                                }
                                name='storeyNotLast'
                                value='1'
                                checked={search.storeyNotLast === '1'}
                                onChange={handleChangeCheckboxValue}
                            >
                                Не последний
                            </ToggleButton>
                        </ButtonGroup>
                    </Col> */}
                    <Col xs={3}>
                        <Form.Group>
                            <Form.Label>
                                <>Общая площадь</>
                            </Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='spaceTotalMin'
                                        value={search?.spaceTotalMin || ''}
                                        onChange={handleChangeValue}
                                        placeholder='от'
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='spaceTotalMax'
                                        value={search?.spaceTotalMax || ''}
                                        onChange={handleChangeValue}
                                        placeholder='до'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <Form.Group>
                            <Form.Label>
                                <>Год постройки</>
                            </Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='houseBuiltMin'
                                        value={search?.houseBuiltMin || ''}
                                        onChange={handleChangeValueNumberValidator}
                                        placeholder='от'
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='houseBuiltMax'
                                        value={search?.houseBuiltMax || ''}
                                        onChange={handleChangeValueNumberValidator}
                                        placeholder='до'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-3 d-flex align-items-center'>
                    <Col className='p-2' xs={1}>
                        <Button type='submit' variant='primary' size='sm'>
                            Найти
                        </Button>
                    </Col>
                    <Col className='d-flex gap-5'>
                        <Checkbox
                            name='subsidizedRate'
                            label='Суб. ставка'
                            onChange={handleChangeCheckboxValue}
                            checked={search.subsidizedRate == '1'}
                        />
                        <Checkbox
                            name='installment'
                            label='Рассрочка'
                            onChange={handleChangeCheckboxValue}
                            checked={search.installment == '1'}
                        />
                        <Checkbox
                            name='isRennovationFlats'
                            label='Ремонт'
                            onChange={handleChangeCheckboxValue}
                            checked={search.isRennovationFlats == '1'}
                        />
                        <Checkbox
                            name='buyWoInitialPay'
                            label='Без ПВ'
                            onChange={handleChangeCheckboxValue}
                            checked={search.buyWoInitialPay == '1'}
                        />
                        <Checkbox
                            name='isExchangeProgramm'
                            label='Обмен'
                            onChange={handleChangeCheckboxValue}
                            checked={search.isExchangeProgramm == '1'}
                        />
                        <Checkbox
                            name='isCompanyShare'
                            label='Акции'
                            onChange={handleChangeCheckboxValue}
                            checked={search.isCompanyShare == '1'}
                        />
                         <Checkbox
                            name='hasUndergroundParking'
                            label='Подземная парковка'
                            onChange={handleChangeCheckboxValue}
                            checked={search.hasUndergroundParking  == '1'}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default SearchForm;
