import { useEffect } from 'react';
import { useQuery } from '~/src/helpers/hooks';
import { selectAuth } from '~/src/store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    all,
    outDateSoon,
} from '~/src/store/realtySecondary/realtySecondarySlice';
import RSListView from '~/src/components/Realty/Secondary';
import { selectAgency, unloadXmlFeeds } from '~/src/store/agencySlice';
import {
    setActiveMenuKey,
    setHandleScrollPosition,
    redirect,
} from '~/src/store/appSlice';
import { allMegasaleRealty, checkMegasale } from '~/src/store/megasaleSlice';

const RealtySecondary = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    const { xmlFeeds } = useSelector(selectAgency);
    const { me } = useSelector(selectAuth);

    useEffect(() => {
        dispatch(setActiveMenuKey('RS'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    useEffect(() => {
        dispatch(checkMegasale());
    }, [dispatch]);

    useEffect(() => {
        if (!query.toString().includes('own') && me) {
            me?.agencyInfo
                ? dispatch(
                    redirect(
                        '/realty-secondary?limit=25&own=my_agency&page=1&sort=created_at_desc',
                    ),
                )
                : dispatch(
                    redirect(
                        '/realty-secondary?limit=25&own=all&page=1&sort=created_at_desc',
                    ),
                );
        }
    }, [dispatch, query]);

    useEffect(() => {
        if (xmlFeeds === null && me?.agencyInfo?.agency?.id) {
            dispatch(unloadXmlFeeds(me?.agencyInfo?.agency?.id));
        }
    }, [dispatch, me?.agencyInfo?.agency?.id, xmlFeeds]);

    useEffect(() => {
        if (query.toString() !== '' && me) {
            dispatch(all(query));
        }
    }, [dispatch, query]);

    useEffect(() => {
        const query = new URLSearchParams();
        query.append('own', 'my_outdated_soon');
        dispatch(outDateSoon(query));
    }, [dispatch, query]);

    return <RSListView />;
};

export default RealtySecondary;
