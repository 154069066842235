import queryString from 'query-string';
import * as api from '~/src/service/developerEstate';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../interfaces/common';
import { redirect, setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { errorNotify, successNotify } from '../helpers/createNotify';

export const sliceName = 'developerEstate';

interface IDeveloperEstateSlice {
    items: IResponseListData<IDEModel>;
    item?: IDEModel;
    search: any;
    errors?: any;
    navLink?: string;
    baseLink?: string;
}

const initialState = {
    items: {},
    search: {},
    navLink: '/developer-estate',
    baseLink: '/developer-estate',
};

const developerEstateSlice: Slice<IDeveloperEstateSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IDeveloperEstateSlice,
            action: TAnyAction<IResponseListData<IDEModel>>,
        ) => {
            state.items = action.payload;
        },
        setSearch: (state: IDeveloperEstateSlice, action: TAnyAction<any>) => {
            state.search = action.payload;
        },
        setNavLink: (
            state: IDeveloperEstateSlice,
            action: TAnyAction<string>,
        ) => {
            state.navLink = action.payload;
        },
    },
});

export const {
    setAll,
    setSearch,
    setNavLink,
} = developerEstateSlice.actions;

export const goTo =
    (params: Record<string, any>) =>
        (dispatch: TAppDispatch, getState: () => TState): void => {
            if (params.reset === true) {
                params = initialState.search;
            } else params = { ...getState().developerEstate.search, ...params };
            const filteredParams = Object.fromEntries(
                Object.entries(params).filter(
                    ([_, value]) =>
                        value !== '' &&
                        (!Array.isArray(value) || value.length > 0)
                )
            );
            dispatch(
                redirect(
                    `/developer-estate?${queryString.stringify(filteredParams, { skipNull: true })}`,
                ),
            );
        };


export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(
            setNavLink(`${initialState.baseLink}?${queryParams.toString()}`),
        );
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const favorite = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        try {
            const response = await api.favorite(id);
            if (response) {
                dispatch(
                    setAll({
                        ...getState().developerEstate.items,
                        items: getState().developerEstate.items?.items?.map(
                            (item: IDEModel) => {
                                if (item.id === id) {
                                    return { ...item, isFavorite: !item.isFavorite };
                                }

                                return item;
                            },
                        ),
                    }),
                );
            }
        } catch (error) {
            errorNotify({
                message: '',
                title: 'Ошибка!',
            });
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const selectDE = (state: TState): IDeveloperEstateSlice => state[sliceName];

export default developerEstateSlice.reducer;


export interface IDEModel {
    id: number;
    feedId?: number;
    developerId?: number;
    housingEstateId?: number;
    housingEstateHouseId?: number;
    url?: string;
    location?: Location;
    price?: string;
    spaceTotal?: string;
    spaceLiving?: string;
    spaceKitchen?: string;
    floor?: number;
    rooms?: number;
    floorsTotal?: number;
    ceiling?: string;
    bathroomUnit?: string;
    floorCovering?: string;
    windowView?: string;
    balcony?: string;
    openPlan?: boolean;
    studio?: boolean;
    apartments?: boolean;
    roomsType?: string;
    buildingState?: string;
    buildingType?: string;
    buildingPhase?: string;
    builtYear?: number;
    readyQuarter?: number;
    buildingName?: string;
    buildingSection?: string;
    description?: string;
    yandexBuildingId?: number;
    yandexHouseId?: number;
    externalId?: string;
    organization?: string;
    phone?: string;
    lift?: boolean;
    rubbishChute?: string;
    isElite?: boolean;
    sold?: boolean;
    createdAt?: string;
    updatedAt?: string;
    images?: IImage[];
    developer?: IDeveloperEstateDeveloper;
    estate?: IDeveloperEstateEstate;
    isFavorite?: boolean;
}

interface IDeveloperEstateDeveloper {
    id?: number;
    title?: string;
    isCrucialPartner?: number;
    email?: string;
    contactPhoneCountryCode?: string;
    contactPhone?: string;
    foundationDate?: string;
    description?: string;
    isSuburban?: number;
    chatLink?: string;
    siteLink?: string;
    fixAgentRules?: string;
    fixAgentLink?: string;
    isExchangeProgramm?: boolean;
    exchangeProgrammDescription?: string;
    createdAt?: Date;
    createdBy?: Date;
    updatedAt?: Date;
    updatedBy?: Date;
}

interface IDeveloperEstateEstate {
    id?: number;
    isEnabled?: boolean;
    title?: string;
    userUid?: string;
    developerId?: number;
    addressPlaceDetailId?: number;
    latitude?: string;
    longitude?: string;
    avitoXmlId?: number;
    youtube?: string;
    siteUrl?: string;
    presentationLink?: string;
    popularity?: number;
    description?: string;
    mortgage?: string;
    cityhouse?: boolean;
    lowRiseEstate?: boolean;
    youngFamily?: boolean;
    escrowAccountSell?: boolean;
    smartHouseSystem?: boolean;
    soundproofing?: boolean;
    isRennovationFlats?: boolean;
    rennovationFlatsDescr?: string;
    wallDecorationChoice?: boolean;
    hasCctv?: boolean;
    isClosedArea?: boolean;
    isProtectedArea?: boolean;
    hasPlayGround?: boolean;
    hasSportGround?: boolean;
    hasPicnicGround?: boolean;
    hasGroundParking?: boolean;
    hasUndergroundParking?: boolean;
    hasBesidePark?: boolean;
    hasBesideWater?: boolean;
    hasBesideForest?: boolean;
    hasBesideMall?: boolean;
    hasBesideSchool?: boolean;
    hasBesidePreSchool?: boolean;
    reward?: string;
    installment?: string;
    installmentDescr?: string;
    buyWoInitialPay?: boolean;
    buyWoInitialPayDescr?: string;
    isExchangeProgramm?: boolean;
    isExchangeProgrammDescr?: string;
    subsidizedRate?: boolean;
    subsidizedRateDescr?: string;
    companyShareDescr?: string;
    isCompanyShare?: boolean;
    saleDepPhone?: string;
    freshAt?: Date;
    freshBy?: Date;
    yandexBuildingId?: number;
    createdAt?: Date;
    createdBy?: Date;
    updatedAt?: Date;
    updatedBy?: Date;
}

interface Location {
    region: string;
    address: string;
    country: string;
    latitude: number;
    longitude: number;
    'locality-name': string;
    'sub-locality-name': string;
}

export interface IImage {
    id: number;
    realtyId: number;
    url: string;
    createdAt: string;
    updatedAt: string;
}
