import React, { useEffect } from 'react';
import { selectApp } from '~/src/store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectChangePersonOnHousing, unloadAll } from '~/src/store/changePersonOnHousingSlice';
import { all as allDeveloper, unload } from '~/src/store/developer/developerSlice';
import { useQuery } from '~/src/helpers/hooks';
import { useParams } from 'react-router-dom';
import { read } from '~/src/store/developerEstateFeed';
import UpdateForm from '~/src/components/DeveloperEstateFeed/update';

const Update = () => {
    const dispatch = useDispatch();
    const query = useQuery();

    const { allHousingEstate } = useSelector(selectChangePersonOnHousing);
    const { loading } = useSelector(selectApp);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (allHousingEstate === null) {
            dispatch(unloadAll());
        }
    }, [dispatch, allHousingEstate]);

    useEffect(() => {
        dispatch(unload());
    }, [dispatch]);


    useEffect(() => {
        dispatch(read(+id));
    }, [id, dispatch]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <UpdateForm />}
        </React.Fragment>
    );
};

export default Update;
