import { FormApi } from 'final-form';
import { useSelector } from 'react-redux';
import MapUpdateView from '~/src/components/Common/Map/MapUpdateView';
import { geocode } from '~/src/service/address';
import { Col, Row } from 'react-bootstrap';
import InputField from '../Common/Form/InputField';
import {
    composeValidators,
    mustBeNumber,
    price,
    required,
    space,
} from '~/src/helpers/validators';
import React from 'react';
import SimpleSelect from '../Common/Form/SimpleSelect';
import { Field, FormSpy, useForm } from 'react-final-form';
import { selectDeveloper } from '~/src/store/developer/developerSlice';
import DropDownSearchApi from '../Common/DropDownSearchApi';
import Error from '../Common/Form/Error';
import CheckboxField from '../Common/Form/CheckboxField';
import Condition from '../Common/Form/Condition';
import { selectDirection } from '~/src/store/directionSlice';
import SimpleSelectV2Field from '../Common/Form/SimpleSelectV2';

export default function UpdateDescriptionView(): JSX.Element {
    const { unloadedSuburban } = useSelector(selectDeveloper);
    const { unloadItems } = useSelector(selectDirection);

    const developers = Array.from(new Map(unloadedSuburban.map(item => [item.id, { value: item.id.toString(), title: item.title }])).values());
    developers.unshift({ value: '', title: '' });

    const form: FormApi = useForm();

    const handleMapClick = async (lat: number, lng: number) => {
        const _res = await geocode(lat, lng);
        lat = _res[0]?.latitude || lat;
        lng = _res[0]?.longitude || lng;
        if (_res[0]?.placeId) {
            form.mutators.setValue('addressPlaceDetailId', _res[0]?.placeId);
            form.mutators.setValue('address', _res[0]?.address);
        }
        form.mutators.setValue('latitude', lat);
        form.mutators.setValue('longitude', lng);
    };

    return (
        <>
            <Row>
                <Col>
                    <InputField
                        name='title'
                        label='Название'
                        validators={required}
                    />
                    <InputField name='header' label='Заголовок' />
                    <InputField
                        name='presentationLink'
                        label='Презентация'
                        placeholder='Ссылка на презентацию'
                    />
                </Col>
                <Col>
                    <SimpleSelect
                        label='Застройщик'
                        name='developerId'
                        opts={developers}
                        validators={required}
                    />
                    <InputField name='subheader' label='Подзаголовок' />
                    <InputField name='websiteUrl' label='Адрес сайта проекта' />
                </Col>
            </Row>
            <Row>
                <Col>
                    {/* <Field name='keyBenefits'>
                        {({ input, meta }) => (
                            <Form.Group className='mb-3'>
                                <Form.Group className='d-flex gap-3'>
                                    <Form.Label>
                                        <>Ключевые приемущества</>
                                    </Form.Label>
                                </Form.Group>
                                <Select
                                    {...input}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    options={keyBenefits}
                                    placeholder='Выберите ключевые приемущества'
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            maxHeight: '100px',
                                            overflowY: 'auto',
                                            '::-webkit-scrollbar': {
                                                width: '4px',
                                            },
                                            '::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#0d6efd',
                                                borderRadius: '2px',
                                            },
                                            '::-webkit-scrollbar-track': {
                                                backgroundColor: '#ECECEC',
                                                borderRadius: '2px',
                                            },
                                        }),
                                    }}
                                />
                                {meta.error && meta.touched && <span style={{
                                    color: 'red',
                                    fontSize: '14px'
                                }}>{meta.error}</span>}
                            </Form.Group>
                        )}
                    </Field>
                    <Field name='benefitsWhenPurchasing'>
                        {({ input, meta }) => (
                            <Form.Group className='mb-3'>
                                <Form.Group className='d-flex gap-3'>
                                    <Form.Label>
                                        <>Выгоды при покупке</>
                                    </Form.Label>
                                </Form.Group>
                                <Select
                                    {...input}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    options={benefitsWhenPurchasing}
                                    placeholder='Выберите выгодные предложения при покупке'
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            maxHeight: '100px',
                                            overflowY: 'auto',
                                            '::-webkit-scrollbar': {
                                                width: '4px',
                                            },
                                            '::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#0d6efd',
                                                borderRadius: '2px',
                                            },
                                            '::-webkit-scrollbar-track': {
                                                backgroundColor: '#ECECEC',
                                                borderRadius: '2px',
                                            },
                                        }),
                                    }}
                                />
                                {meta.error && meta.touched && <span style={{
                                    color: 'red',
                                    fontSize: '14px'
                                }}>{meta.error}</span>}
                            </Form.Group>
                        )}
                    </Field> */}
                    <DropDownSearchApi
                        label='Адрес'
                        name='address'
                        defaultValue={''}
                        onSelect={(item) => {
                            form.mutators.setValue(
                                'addressPlaceDetailId',
                                item.placeId,
                            );
                            form.mutators.setValue('address', item.address);
                            form.mutators.setValue('latitude', item.latitude);
                            form.mutators.setValue('longitude', item.longitude);
                        }}
                    >
                        <Error name='addressPlaceDetailId' />
                    </DropDownSearchApi>
                    <InputField
                        name='latitude'
                        validators={required}
                        label={'Широта'}
                        readOnly
                    />
                    <InputField
                        name='longitude'
                        validators={required}
                        label={'Долгота'}
                        readOnly
                    />
                    <Field name='directionId' validate={required}>
                        {({ input, meta }) => (
                            <SimpleSelectV2Field
                                label='Тракт'
                                name='directionId'
                                validators={required}
                            >
                                <option></option>
                                {unloadItems.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.title}
                                    </option>
                                ))}
                            </SimpleSelectV2Field>
                        )}
                    </Field>
                    <InputField
                        label='Скидка'
                        name='landSaleDiscount'
                        placeholder='Скидка (без %)'
                        validators={composeValidators(price)}
                        onChange={(e) =>
                            form.mutators.setPriceValue(
                                'landSaleDiscount',
                                e.target.value,
                            )
                        }
                    />
                    <CheckboxField name={'allHouseProjects'} label={'Возможны любые подряды'} />
                </Col>
                <Col>
                    <FormSpy subscription={{ values: true }}>
                        {({ values }) => (
                            <MapUpdateView
                                onClick={handleMapClick}
                                latitude={values.latitude}
                                longitude={values.longitude}
                            />
                        )}
                    </FormSpy>
                </Col>
            </Row>
            {/* <Field name='description'>
                {({ input, meta }) => (
                    <Form.Group className='mb-3'>
                        <Form.Label>Описание</Form.Label>
                        <Form.Control
                            {...input}
                            as='textarea'
                            rows={5}
                            size='sm'
                            isInvalid={meta.error || meta.submitError}
                            placeholder='Описание'
                        />
                        <Form.Control.Feedback type='invalid'>
                            {meta.error || meta.submitError}
                        </Form.Control.Feedback>
                    </Form.Group>
                )}
            </Field>
            <ConstructionPhasesFormBlock /> */}
        </>
    );
}
