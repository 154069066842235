import { useState, BaseSyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, InputGroup } from 'react-bootstrap';

export default function QuickSearch({ search, goTo }): JSX.Element {
    const dispatch = useDispatch();


    const [value, setValue] = useState(search?.q || '');

    const handleChange = (e: BaseSyntheticEvent) => {
        setValue(e.target.value);
    };

    const handleSubmit = async () => {
        dispatch(goTo({ page: 1, q: value }));
    };
    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            dispatch(goTo({ page: 1, q: value }));
        }
    };

    return (
        <>
            <Form.Label>&nbsp;</Form.Label>
            <InputGroup className='mb-3' size='sm'>
                <Form.Control
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    autoComplete='off'
                />
                <Button type='submit' onClick={handleSubmit}>
                    Найти
                </Button>
            </InputGroup>
        </>
    );
}
