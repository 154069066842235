import { useAppSelector } from '~/src/helpers/hooks';
import ReactSelectField from '../../Common/Form/ReactSelectField';
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import ToggleButtonField from '../../Common/Form/ToggleButtonWrapper';
import { selectDeveloper } from '~/src/store/developer/developerSlice';
import { useForm, useFormState } from 'react-final-form';
import InputField from '../../Common/Form/InputField';

const SearchFormPreview = ({ showForm }): JSX.Element => {
    const { unloadedSuburban } = useAppSelector(selectDeveloper);
    const form = useForm();
    const { values } = useFormState();

    const developers = Array.from(new Map(unloadedSuburban.map(item => [item.id, { value: item.id, label: item.title }])).values());

    return (
        <>
            <Row>
                <Col>
                    <ReactSelectField
                        name='developerId'
                        label={'Застройщик'}
                        value={developers.filter((r) => values?.developerId?.includes(r.value))}
                        isMulti={true}
                        onChange={(v) => form.mutators.setSelectValue('developerId', v)}
                        options={developers}
                    />
                </Col>
                <Col>
                    <InputField
                        name='title'
                        label='Название проекта'
                    />
                </Col>
                <Col>
                    <Form.Group className='mb-2'>
                        <div>
                            <Form.Label>Этажей</Form.Label>
                        </div>
                        <ButtonGroup>
                            {[1, 2, 3].map((radio, idx) => (
                                <ToggleButtonField
                                    key={`storey-${idx}`}
                                    label={`${radio}`}
                                    name='storey'
                                    size='sm'
                                    type='checkbox'
                                    value={radio}
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                />
                            ))}
                        </ButtonGroup>
                    </Form.Group>
                </Col>

            </Row>
            {
                !showForm &&
                <Button
                    className='mb-3'
                    type='submit'
                    size='sm'
                    variant='primary'
                >
                    Найти
                </Button>
            }
        </>
    );

};

export { SearchFormPreview };
