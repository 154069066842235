import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateForm from '~/src/components/CottageVillage/create';
import {
    selectDeveloper,
    unload,
    unloadSuburban,
} from '~/src/store/developer/developerSlice';
import { selectApp, setHandleScrollPosition } from '~/src/store/appSlice';
import {
    selectDirection,
    unload as unloadDirection,
} from '~/src/store/directionSlice';

export default function CreatePage(): JSX.Element {
    const dispatch = useDispatch();
    const { loading } = useSelector(selectApp);
    const { unloadItems } = useSelector(selectDirection);

    useEffect(() => {
        if (unloadItems.length === 0) {
            dispatch(unloadDirection());
        }
    }, [dispatch, unloadItems]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    useEffect(() => {
        dispatch(unloadSuburban());
    }, [dispatch]);

    return (
        <React.Fragment>{loading ? <>...</> : <CreateForm />}</React.Fragment>
    );
}
