import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateForm from '~/src/components/HousingEstate/create';
import { unload, selectDeveloper, all } from '~/src/store/developer/developerSlice';
import { selectApp, setHandleScrollPosition } from '~/src/store/appSlice';

export default function CreatePage(): JSX.Element {
    const dispatch = useDispatch();
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    useEffect(() => {
        dispatch(unload());
    }, [dispatch]);

    return (
        <React.Fragment>{loading ? <>...</> : <CreateForm />}</React.Fragment>
    );
}
