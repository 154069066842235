import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeveloperEstateListView from '~/src/components/DeveloperEstate';
import { useQuery } from '~/src/helpers/hooks';
import { setActiveMenuKey, setHandleScrollPosition } from '~/src/store/appSlice';
import { all, setAll } from '~/src/store/developerEstate';

const DeveloperEstate = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenuKey('DE'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    return (
        <DeveloperEstateListView />
    );
};

export default DeveloperEstate;
