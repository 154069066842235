import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import RSListPhotos from './RSListPhotos';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import HaveAccess from '../../Common/HaveAccess';
import MainPopup from '../../Common/ModalUI/MainPopup';
import { useDispatch, useSelector } from 'react-redux';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import Confirm from '../../Common/ModalUI/AlertModals/Confirm';
import { IRSModel, setSoldOff, transfer, xmlfeedaction } from '~/src/store/realtySecondary/realtySecondarySlice';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import ButtonWithConfirm from '~/src/components/Common/ModalUI/ButtonWithConfirm';
import {
    setSold,
    favorite,
    remove,
    fresh,
} from '~/src/store/realtySecondary/realtySecondarySlice';

import classNames from 'classnames';
import { selectAuth } from '~/src/store/authSlice';
import * as APConstants from '~/src/constants/agency.permission';
import ButtonWithXmlFeedRealtyControl, { IItem as IItemXmlFeed } from '../CommonRealty/ButtonWithXmlFeedRealtyControl';

import IListPriceRise from './IListPriceRise';
import cianLogo from '~/src/assets/img/cian.png';
import avitoLogo from '~/src/assets/img/avito.png';
import yandexLogo from '~/src/assets/img/yandex.png';
import domclickLogo from '~/src/assets/img/domclick.png';
import ButtonWithTransferControl, { IItem as IItemTranser } from '../../Common/Button/ButtonWithTransferControl';

import PriceChangedAgo from './PriceChangedAgo';
import IListUpdateInfo from './IListUpdateInfo';
import DaysOrHoursForRemove from './DaysOrHoursForRemove';
import { selectMegaSale } from '~/src/store/megasaleSlice';
import ButtonWithAddMegaSale from '../../Sale/ButtonWithAddMegaSale';
import InfoXmlFeed from '../CommonRealty/InfoXmlFeed';
import EncumbranceUnderstatementNeed from '../CommonRealty/EncumbranceUnderstatementNeed';
import getNoun from '~/src/helpers/getNoun';

const IList: FC<TListProps> = ({ item }) => {
    const dispatch = useDispatch();
    const [showOnMap, setShowOnMap] = useState(false);
    const [soldConfirmShow, setSoldConfirmShow] = useState(false);
    const [soldOffConfirmShow, setSoldOffConfirmShow] = useState(false);
    const [deleteConfirmShow, setDeleteConfirmShow] = useState(false);
    const { me } = useSelector(selectAuth);
    const { megasaleActive } = useSelector(selectMegaSale);
    const handleClickAddress = () => setShowOnMap(true);

    const setMap = (map) => {
        const resizeObserver = new ResizeObserver(() => {
            map.invalidateSize();
        });

        const container = document.getElementById(`map-container-${item?.id}`);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        resizeObserver.observe(container!);
    };

    const viewPriceHistoryColor = (price, priceCompare) => {
        if (Number(price) > Number(priceCompare)) {
            return 'red';
        } else {
            return 'green';
        }
    };

    const handleSoldClick = () => {
        dispatch(setSold(item?.id));
        setSoldConfirmShow(false);
    };
    const handleSoldOffClick = () => {
        dispatch(setSoldOff(item.id));
        setSoldOffConfirmShow(false);
    };
    const handleFreshClick = () => {
        dispatch(fresh(item?.id));
    };

    const handleFavoriteClick = () => {
        dispatch(favorite(item?.id));
    };

    const handleDeleteClick = () => {
        dispatch(remove(item?.id));
        setDeleteConfirmShow(false);
    };

    const presentationLink = `${process.env.REACT_APP_WEBSITE_URL}/realty_secondary/${item?.id}?agent=${me?.phone}`;

    const copyPresentationLink = async () => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(presentationLink);
        }
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <Link to={`/realty-secondary/card/${item?.id}`}>{item?.id}</Link>
                    {item?.verified && (
                        <React.Fragment>
                            <br />
                            <FontAwesomeIcon
                                icon={['fas', 'check-circle']}
                                style={{ color: 'blue' }}
                                title='Подтвержденный объект'
                            />
                        </React.Fragment>
                    )}
                    <br />
                    {!item?.isFullfied && (
                        <React.Fragment>
                            <FontAwesomeIcon
                                icon={['fas', 'exclamation-triangle']}
                                title={item?.fullfiedStatus
                                    ?.map((field) => {
                                        return `${field.key}: ${field.value === true ? 'Да' : 'Нет'}`;
                                    })
                                    .join('\n')}
                            />
                        </React.Fragment>
                    )}
                    {item?.xmlfeedunload && item?.xmlfeedunload.length > 0 && (
                        <InfoXmlFeed xmlfeedunload={item?.xmlfeedunload} />
                    )}
                    {item?.isExclusive && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>Эксклюзивный</Badge>
                        </React.Fragment>
                    )}
                    {item?.isDiscount && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>Промо</Badge>
                        </React.Fragment>
                    )}
                </td>
                <td>
                    <RSListPhotos photos={item?.photos} />
                    <p>
                        Внесен: {dateFormat(item?.createdAt, 'dd.mm.yyyy')}
                        <br />
                        <IListUpdateInfo item={item} />
                        <PriceChangedAgo {...item?.priceChangedAgo} />
                        <DaysOrHoursForRemove {...item} />
                        {
                            item?.freshAgo <= 5 && (
                                <React.Fragment>
                                    <br />
                                    <Badge className='bg-danger'>Скоро устареет</Badge>
                                </React.Fragment>
                            )
                        }
                    </p>
                    {item?.sold && <Badge bg='danger'>В архиве</Badge>}
                    {item?.videoUrl &&
                        <Link
                            to={item?.videoUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='btn btn-sm btn-success'
                            title='Видео'
                        >
                            <FontAwesomeIcon icon={['fas', 'video']} />{' '}
                            Видео
                        </Link>
                    }
                </td>
                <td>
                    <p>
                        {item?.realtyType === 'secondary' ? (
                            <strong>Вторичка</strong>
                        ) : (
                            <strong>Новостройка от дольщика</strong>
                        )}
                    </p>
                    <p>
                        <strong>Тип</strong>
                        <br />
                        <FormattedMessage
                            id={`realty_secondary_subtype_${item?.realtySubtype}`}
                        />
                    </p>
                    {item?.housingEstate && (
                        <p>{item?.housingEstate?.title}</p>
                    )}
                    {item?.addressDetail?.livingArea && (
                        <p>
                            <strong>Район</strong> <br />
                            {item?.addressDetail?.livingArea}
                        </p>
                    )}
                    <p>
                        <strong>Адрес</strong>
                        <br />
                        <Button
                            size='sm'
                            type='button'
                            variant='link'
                            onClick={handleClickAddress}
                        >
                            {item?.addressDetail?.formattedAddress}
                        </Button>
                    </p>
                    {item?.storey && item?.houseStoreys && (
                        <p>
                            <strong>Этаж</strong>
                            <br />
                            {item?.storey} / {item?.houseStoreys}
                        </p>
                    )}
                    {item?.isMy && (
                        <p>
                            <strong>Квартира</strong>
                            <br />
                            {item?.flatNumber}
                        </p>
                    )}
                </td>
                <td>
                    {item?.rooms && (
                        <p>
                            <strong>Комнат</strong>
                            <br />
                            {item?.rooms}
                        </p>
                    )}
                    <strong>Площадь:</strong>
                    <br />
                    {item?.spaceTotal && (
                        <p>
                            <span>Общая: </span>
                            {item?.spaceTotal}
                        </p>
                    )}
                    {item?.spaceLiving && (
                        <p>
                            <span>Жилая: </span>
                            {item?.spaceLiving}
                        </p>
                    )}
                    {item?.spaceKitchen && (
                        <p>
                            <span>Кухня: </span>
                            {item?.spaceKitchen}
                        </p>
                    )}
                    {(Number(item?.bathroomCombined) > 0 ||
                        Number(item?.bathroomSeparated) > 0) && (
                            <p>
                                <strong>Санузлы</strong>
                                {Number(item?.bathroomCombined) > 0 && (
                                    <React.Fragment>
                                        <br />
                                        <span>
                                            Совмещенных:{' '}
                                            {Number(item?.bathroomCombined)}
                                        </span>
                                    </React.Fragment>
                                )}
                                {Number(item?.bathroomSeparated) > 0 && (
                                    <React.Fragment>
                                        <br />
                                        <span>
                                            Раздельных: {item?.bathroomSeparated}
                                        </span>
                                    </React.Fragment>
                                )}
                            </p>
                        )}
                    {item?.balcony > 0 && (
                        <p>
                            <strong>Балкон: </strong> {item?.balcony}
                        </p>
                    )}
                    {item?.loggia > 0 && (
                        <p>
                            <strong>Лоджия: </strong> {item?.loggia}
                        </p>
                    )}
                </td>
                <td>
                    {item?.houseMaterial && (
                        <p>
                            <strong>Материал стен</strong>
                            <br />
                            <FormattedMessage
                                id={`house_material_${item?.houseMaterial}`}
                            />
                        </p>
                    )}

                    {item?.houseBuilt && (
                        <p>
                            {item?.realtyType == 'primary' ? (
                                <>
                                    <strong>Срок сдачи дома</strong>
                                    <br />
                                    {`${item?.houseBuiltQuarter} кв. ${item?.houseBuilt} г.`}
                                </>
                            ) : (
                                <>
                                    <strong>Год постройки</strong>
                                    <br />
                                    {`${item?.houseBuilt} г.`}
                                </>
                            )}
                            <br />
                        </p>
                    )}

                    {(item?.serviceElevator > 0 ||
                        item?.passengerElevator > 0) && (
                            <p>
                                <strong>Лифты</strong>
                                {item?.passengerElevator > 0 && (
                                    <React.Fragment>
                                        <br />
                                        <span>
                                            Пассажирских: {item?.passengerElevator}
                                        </span>
                                    </React.Fragment>
                                )}
                                {item?.serviceElevator > 0 && (
                                    <React.Fragment>
                                        <br />
                                        <span>
                                            Грузовых: {item?.serviceElevator}
                                        </span>
                                    </React.Fragment>
                                )}
                            </p>
                        )}
                </td>
                <td>
                    <p>
                        {(item?.megasale && megasaleActive) ? (
                            <>
                                <strong style={{ color: 'red' }}>Цена</strong>
                                <br />
                                <span style={{ color: 'red' }}>
                                    {new Intl.NumberFormat('ru-RU').format(+item?.price)}
                                </span>
                                <br />
                                <strong style={{ color: 'green' }}>Цена для распродажи</strong>
                                <br />
                                <span style={{ color: 'green' }}>
                                    {new Intl.NumberFormat('ru-RU').format(+item?.megasale?.price)}
                                </span>
                                <br />
                                <strong>Скидка</strong>
                                <br />
                                <span>
                                    {new Intl.NumberFormat('ru-RU').format(
                                        +item?.price - +item?.megasale?.price
                                    )}
                                </span>
                                <br />
                                <IListPriceRise item={item} />
                            </>
                        ) : (
                            <>
                                <strong>Цена</strong>
                                <br />
                                {item?.priceHistory.length > 0 ? (
                                    <>
                                        <span
                                            style={{
                                                color: viewPriceHistoryColor(
                                                    item?.priceHistory[0]?.newPrice,
                                                    item?.priceHistory[0]?.oldPrice
                                                ),
                                            }}
                                        >
                                            {new Intl.NumberFormat('ru-RU').format(+item?.price)}
                                        </span>{' '}
                                        <br />
                                        <strong>Прошлая цена</strong>
                                        <br />
                                        <span
                                        >
                                            {new Intl.NumberFormat('ru-RU').format(+item?.priceHistory[0]?.oldPrice)}
                                        </span>{' '}
                                    </>
                                ) : (
                                    <>
                                        {new Intl.NumberFormat('ru-RU').format(+item?.price)}{' '}
                                    </>
                                )}
                                <br />
                                <IListPriceRise item={item} />
                            </>
                        )}
                        <br></br>
                        <strong>
                            Цена за м<sup>2</sup>
                        </strong>
                        <br></br>
                        <span>
                            {new Intl.NumberFormat('ru-RU').format(
                                Math.floor(+item?.price / +item?.spaceTotal),
                            )}
                        </span>
                    </p>
                    <EncumbranceUnderstatementNeed item={item} />
                </td>
                <td>
                    {item?.isMy ? (
                        <React.Fragment>
                            {item?.sellerName && (
                                <p>
                                    <strong>Продавец</strong>
                                    <br />
                                    {item?.sellerName}
                                </p>
                            )}

                            {item?.sellerPhone && (
                                <p>
                                    <strong>Телефон</strong>
                                    <br />
                                    {phoneFormatter(`${item?.sellerPhone}`)}
                                </p>
                            )}
                        </React.Fragment>
                    ) : (
                        item?.agent && (
                            <React.Fragment>
                                {item?.agent?.agencyInfo?.agency && (
                                    <p>
                                        <strong>Агентство</strong>
                                        <br></br>
                                        <span>
                                            {
                                                item?.agent?.agencyInfo?.agency
                                                    .title
                                            }
                                        </span>
                                    </p>
                                )}
                                <p>
                                    <strong>
                                        {item?.agent?.role != 'agent'
                                            ? 'Собственник'
                                            : 'Агент'}
                                    </strong>
                                    <br />
                                    {item?.agent?.fullname}
                                </p>

                                <p>
                                    <strong>Телефон</strong>
                                    <br />
                                    {phoneFormatter(`${item?.agent?.phone}`)}
                                </p>
                            </React.Fragment>
                        )
                    )}
                </td>
                <td>
                    <ButtonGroup vertical={true} className='w-100'>
                        <Link
                            to={`/realty-secondary/card/${item?.id}`}
                            className='btn btn-sm btn-secondary'
                            title='Карточка'
                        >
                            Карточка
                        </Link>
                        <HaveAccess
                            access='realty.secondary.all'
                            agencyId={item?.agent?.agencyInfo?.agency?.id}
                            groupId={item?.agent?.agencyInfo?.group?.id}
                            agencyAccess={
                                APConstants.REALTY_SECONDARY_AGENCY_UPDATE
                            }
                            groupAccess={
                                APConstants.REALTY_SECONDARY_GROUP_UPDATE
                            }
                            me={item?.agent?.uid}
                            roles='grand'
                        >
                            <Link
                                to={`/realty-secondary/edit/${item?.id}`}
                                className='btn btn-sm btn-warning'
                                title='Редактировать'
                            >
                                Редактировать
                            </Link>
                        </HaveAccess>
                        <HaveAccess
                            access='realty.secondary.all'
                            me={item?.agent?.uid}
                            roles='grand'
                        >
                            <ButtonWithConfirm
                                label='Продлить'
                                modalText={`Продлить ${item?.id}?`}
                                onClick={handleFreshClick}
                                variant='info'
                            />
                        </HaveAccess>
                        {item?.favorite ? (
                            <Button
                                size='sm'
                                variant='primary'
                                title='Убрать из избранного'
                                onClick={handleFavoriteClick}
                            >
                                <FontAwesomeIcon icon={['fas', 'heart']} />{' '}
                                Убрать из избранного
                            </Button>
                        ) : (
                            <Button
                                size='sm'
                                variant='outline-primary'
                                title='Добавить в избранное'
                                onClick={handleFavoriteClick}
                            >
                                <FontAwesomeIcon icon={['fas', 'heart']} />{' '}
                                Добавить в избранное
                            </Button>
                        )}
                        <Dropdown className='w-100'>
                            <Dropdown.Toggle
                                className='w-100'
                                variant='secondary'
                                size='sm'
                            >
                                Презентация
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    href={presentationLink}
                                    target='_blank'
                                >
                                    Смотреть
                                </Dropdown.Item>
                                <Dropdown.Item onClick={copyPresentationLink}>
                                    Скопировать ссылку
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <HaveAccess
                            access='realty.secondary.all'
                            agencyId={item?.agent?.agencyInfo?.agency?.id}
                            groupId={item?.agent?.agencyInfo?.group?.id}
                            agencyAccess={
                                APConstants.REALTY_SECONDARY_AGENCY_UPDATE
                            }
                            groupAccess={
                                APConstants.REALTY_SECONDARY_GROUP_UPDATE
                            }
                            me={item?.agent?.uid}
                            roles='grand'
                        >
                            {item?.sold === false && (
                                <Button
                                    type='button'
                                    size='sm'
                                    variant='danger'
                                    onClick={() => setSoldConfirmShow(true)}
                                >
                                    В архив
                                </Button>
                            )}
                        </HaveAccess>
                        <HaveAccess
                            agencyId={item?.agent?.agencyInfo?.agency?.id}
                            groupId={item?.agent?.agencyInfo?.group?.id}
                            agencyAccess={
                                APConstants.REALTY_SECONDARY_AGENCY_UPDATE
                            }
                            groupAccess={
                                APConstants.REALTY_SECONDARY_GROUP_UPDATE
                            }
                            me={item?.agent?.uid}
                            roles='grand'
                        >
                            {item?.sold === true && (
                                <Button
                                    type='button'
                                    size='sm'
                                    variant='success'
                                    onClick={() => setSoldOffConfirmShow(true)}
                                >
                                    Вернуть в продажу
                                </Button>
                            )}
                        </HaveAccess>
                        <HaveAccess access='realty.secondary.delete'>
                            <Button
                                type='button'
                                size='sm'
                                variant='danger'
                                onClick={() => setDeleteConfirmShow(true)}
                            >
                                Удалить
                            </Button>
                        </HaveAccess>
                        <HaveAccess
                            access='xml.feed.realty.add'
                            agencyId={item?.agent?.agencyInfo?.agency?.id}
                            agencyAccess={APConstants.XML_FEED_REALTY_ADD}
                            roles='grand'
                        >
                            <ButtonWithXmlFeedRealtyControl item={item as IItemXmlFeed} xmlfeedaction={xmlfeedaction} />
                        </HaveAccess>
                        <HaveAccess
                            access='realty.secondary.transfer'
                            agencyId={item?.agent?.agencyInfo?.agency?.id}
                            groupId={item?.agent?.agencyInfo?.group?.id}
                            agencyAccess={
                                APConstants.REALTY_SECONDARY_AGENCY_TRANSFER
                            }
                            groupAccess={
                                APConstants.REALTY_SECONDARY_GROUP_TRANSFER
                            }
                            roles='grand'
                        >
                            <ButtonWithTransferControl item={item as IItemTranser} transfer={transfer} />
                        </HaveAccess>
                        <HaveAccess
                            access='realty.secondary.all'
                            agencyId={item?.agent?.agencyInfo?.agency?.id}
                            groupId={item?.agent?.agencyInfo?.group?.id}
                            agencyAccess={
                                APConstants.REALTY_SECONDARY_AGENCY_UPDATE
                            }
                            groupAccess={
                                APConstants.REALTY_SECONDARY_GROUP_UPDATE
                            }
                            me={item?.agent?.uid}
                            roles='grand'
                        >
                            {megasaleActive && !item?.sold && item?.verified ? (
                                <ButtonWithAddMegaSale
                                    item={item}
                                    type='secondary'
                                />
                            ) : null}
                        </HaveAccess>
                    </ButtonGroup>
                </td>
            </tr>
            <MainPopup
                isOpened={showOnMap}
                onClose={() => setShowOnMap(false)}
                fullwidth={true}
            >
                <MapContainer
                    center={[
                        +item?.addressDetail.latitude,
                        +item?.addressDetail.longitude,
                    ]}
                    zoom={15}
                    maxZoom={18}
                    id={`map-container-${item?.id}`}
                    whenCreated={setMap}
                    attributionControl={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='http://{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}'
                        subdomains={['tile0', 'tile1', 'tile2', 'tile3']}
                    />
                    <Marker
                        position={[
                            +item?.addressDetail.latitude,
                            +item?.addressDetail.longitude,
                        ]}
                    ></Marker>
                </MapContainer>
            </MainPopup>
            <Confirm
                text='Отправить в архив?'
                onClose={() => setSoldConfirmShow(false)}
                isOpened={soldConfirmShow}
                primaryButtonOnClick={handleSoldClick}
                secondaryButtonOnClick={() => setSoldConfirmShow(false)}
            />
            <Confirm
                text='Вернуть в продажу?'
                onClose={() => setSoldOffConfirmShow(false)}
                isOpened={soldOffConfirmShow}
                primaryButtonOnClick={handleSoldOffClick}
                secondaryButtonOnClick={() => setSoldOffConfirmShow(false)}
            />
            <Confirm
                text='Удалить? Это действие безвозвратно!'
                onClose={() => setDeleteConfirmShow(false)}
                isOpened={deleteConfirmShow}
                primaryButtonOnClick={handleDeleteClick}
                secondaryButtonOnClick={() => setDeleteConfirmShow(false)}
            />
        </React.Fragment>
    );
};

type TListProps = {
    item: IRSModel;
};

export default IList;
