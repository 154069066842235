/* eslint-disable react/no-unescaped-entities */
import { useQuery } from '~/src/helpers/hooks';
import { redirect } from '~/src/store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BaseSyntheticEvent, useCallback } from 'react';
import Pagination from '~/src/components/Common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import { all, goTo, selectDEF } from '~/src/store/developerEstateFeed';
import DeveloperEstateFeedListRow from './ListRow';
import HaveAccess from '../Common/HaveAccess';
import { Link } from 'react-router-dom';
import QuickSearch from '../Common/FormFilter/QuickSearchText';

const DeveloperEstateFeedListView = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    const { search, items } = useSelector(selectDEF);

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    return (
        <>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <HaveAccess
                        access='housing.estate.feed.update'
                    >
                        <Link
                            to={'/developer-estate-feed/create/'}
                            className='btn btn-sm btn-primary'
                        >
                            <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить фид
                        </Link>
                    </HaveAccess>
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Row className='mb-2'>
                <Col xs={4}>
                    <Form.Label>
                        <strong>Всего объектов:</strong> {items.count}
                    </Form.Label>
                    <QuickSearch search={search} goTo={goTo} />
                </Col>
            </Row>
            <Col
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                }}
            >
                {items && items.pagination?.have && (
                    <Pagination
                        pagination={items.pagination}
                        onClick={handlePaginationClick}
                    />
                )}
            </Col>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>id</td>
                        <td>Название</td>
                        <td>Информация</td>
                        <td>Источник</td>
                        <td>Аналитика</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.items && items.items.length > 0
                        ? items.items.map((item) => (
                            <DeveloperEstateFeedListRow key={item.id} item={item} />
                        ))
                        : null}
                </tbody>
            </Table>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </>
    );
};

export default DeveloperEstateFeedListView;
