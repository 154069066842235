import { useDispatch } from 'react-redux';
import { useAppSelector } from '~/src/helpers/hooks';
import { Form as FinalForm } from 'react-final-form';
import { goTo, selectDHP } from '~/src/store/developer/developer.house.project';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';

const SearchForm = ({ children }): JSX.Element => {
    const dispatch = useDispatch();
    const { search } = useAppSelector(selectDHP);

    const onSubmit = (values: Record<string, any>) => {
        dispatch(goTo({
            ...values,
            priceMin: values?.priceMin?.toString().replaceAll(/\D/g, ''),
            priceMax: values?.priceMax?.toString().replaceAll(/\D/g, ''),
            priceDiscountMin: values?.priceDiscountMin?.toString().replaceAll(/\D/g, ''),
            priceDiscountMax: values?.priceDiscountMax?.toString().replaceAll(/\D/g, ''),
        }));
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
                setPriceValue: ([field, value], state, { changeValue }) => {
                    value = value.replaceAll(/\D/g, '');
                    changeValue(state, field, () =>
                        new Intl.NumberFormat('ru-RU').format(value),);
                },
                setDecimalValue: (
                    [field, value],
                    state,
                    { changeValue },
                ) => {
                    value = value.replaceAll(',', '.');
                    changeValue(state, field, () => value);
                },
                setSelectValue: (
                    [field, value],
                    state,
                    { changeValue },
                ) => {
                    changeValue(state, field, () => [
                        ...value.map((v) => v.value),
                    ]);
                },
            }}
            initialValues={{
                ...createInitialFormValues(search),
            }}
            render={({ handleSubmit, form }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        {children}
                    </form>
                );
            }}
        />
    );
};

export { SearchForm };
