import phoneFormatter from '~/src/helpers/phoneFormatter';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import React, { FC, useState } from 'react';
import dateFormat from 'dateformat';
import { favorite, IDEModel } from '~/src/store/developerEstate';
import Photos from './Photos';
import { Link } from 'react-router-dom';
import OverlayingPopup from '../Common/ModalUI/OverlayingPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { LinkifyText } from '../Common/LinkifyText';
import { useDispatch } from 'react-redux';

const YesOrNotItemDescr = ({ item, itemDescr, createContentModal, title }) => {
    return (
        <>
            {
                item
                    ? itemDescr
                        ? <>
                            <Button
                                variant='link'
                                size='sm'
                                onClick={() => createContentModal(itemDescr)}
                            >
                                {title}
                            </Button >
                            <br />
                        </>
                        : <>
                            <Button
                                variant='link'
                                size='sm'
                                className='text-dark text-decoration-none'
                                style={{ cursor: 'auto' }}
                            >
                                {title}
                            </Button >
                            <br />
                        </>
                    : null
            }
        </>
    );
};

const DeveloperEstateListRow: FC<{ item: IDEModel }> = ({ item }) => {

    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);


    const createContentModal = (content: string) => {
        setIsModalOpen(true);
        if (content) {
            setModalContent(content);
        } else {
            setModalContent('...');
        }
    };

    const onClose = () => {
        setIsModalOpen(false);
    };

    const handleFavoriteClick = () => {
        dispatch(favorite(item?.id));
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <p>{item.id}</p>
                </td>
                <td>
                    <Photos photos={item?.images || []} />
                    {item.createdAt && (
                        <p>
                            Создано:{' '}
                            {dateFormat(item.createdAt, 'dd.mm.yyyy hh:MM:ss')}
                        </p>
                    )}
                    {item.updatedAt && (
                        <p>
                            Обновлен:{' '}
                            {dateFormat(item.updatedAt, 'dd.mm.yyyy hh:MM:ss')}
                        </p>
                    )}
                </td>
                <td>
                    <p>
                        <strong>Новостройка от застройщика</strong>
                        <br />
                    </p>
                    <p>
                        <strong>ЖК: </strong><span>{item?.estate?.title}</span>
                    </p>
                    <p>
                        <strong>Дом: </strong><span>{item?.buildingName}</span>
                    </p>
                    <p>
                        <strong>Адрес</strong>
                        <br />
                        <Button
                            size='sm'
                            type='button'
                            variant='link'
                        // onClick={handleClickAddress}
                        >
                            {item?.location?.address}
                        </Button>
                    </p>
                    <p>
                        <strong>Этаж</strong>
                        <br />
                        <span>
                            {item?.floor}/{item?.floorsTotal}
                        </span>
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Комнат</strong>
                        <br />
                        <span>{item?.rooms}</span>
                    </p>
                    <p>
                        <strong>Площадь: </strong>
                        <br />
                        {
                            item?.spaceTotal &&
                            <span>Общая: {item?.spaceTotal}</span>
                        }
                        <br />
                        {
                            item?.spaceLiving &&
                            <span>Жилая: {item?.spaceLiving}</span>
                        }
                        <br />
                        {
                            item?.spaceKitchen &&
                            <span>Кухня: {item?.spaceKitchen}</span>
                        }
                    </p>
                </td>
                <td>
                    {
                        item?.buildingType &&
                        <p>
                            <strong>Материал стен</strong>
                            <br />
                            <span>{item?.buildingType}</span>
                        </p>
                    }
                    <p>
                        <strong>Срок сдачи дома</strong>
                        <br />
                        <span>{item?.readyQuarter} кв. {item?.builtYear} год.</span>
                    </p>
                    {
                        item?.lift && <p>
                            <strong>Лифт</strong>
                        </p>
                    }
                </td>
                <td>
                    <p>
                        <strong>Цена</strong>
                        <br />
                        <span>{new Intl.NumberFormat('ru-RU').format(+item?.price)}</span>

                    </p>
                    <p>
                        <strong>Цена за м<sup>2</sup></strong>
                        <br />
                        <span>
                            {new Intl.NumberFormat('ru-RU').format(
                                Math.floor(+item?.price / +item?.spaceTotal),
                            )}
                        </span>
                    </p>
                    <p>
                        <YesOrNotItemDescr
                            item={item?.estate?.subsidizedRate}
                            itemDescr={item?.estate?.subsidizedRateDescr}
                            createContentModal={createContentModal}
                            title={'Суб. ставка'}
                        />
                        <YesOrNotItemDescr
                            item={item?.estate?.installment}
                            itemDescr={item?.estate?.installmentDescr}
                            createContentModal={createContentModal}
                            title={'Рассрочка'}
                        />
                        <YesOrNotItemDescr
                            item={item?.estate?.isRennovationFlats}
                            itemDescr={item?.estate?.rennovationFlatsDescr}
                            createContentModal={createContentModal}
                            title={'Ремонт'}
                        />
                        <YesOrNotItemDescr
                            item={item?.estate?.buyWoInitialPay}
                            itemDescr={item?.estate?.buyWoInitialPayDescr}
                            createContentModal={createContentModal}
                            title={'Без ПВ'}
                        />
                        <YesOrNotItemDescr
                            item={item?.developer?.isExchangeProgramm}
                            itemDescr={item?.developer?.exchangeProgrammDescription}
                            createContentModal={createContentModal}
                            title={'Обмен'}
                        />
                        <YesOrNotItemDescr
                            item={item?.estate?.isCompanyShare}
                            itemDescr={item?.estate?.companyShareDescr}
                            createContentModal={createContentModal}
                            title={'Акции'}
                        />
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Застройщик</strong>
                        <br />
                        {
                            item?.developer.siteLink
                                ? <a href={item?.developer.siteLink} target='_blank' rel='noopener noreferrer'>
                                    <span>{item?.developer?.title}</span>
                                </a>
                                : <span>{item?.developer?.title}</span>
                        }

                    </p>
                    <p>
                        <strong>Телефон:</strong>
                        <br />
                        <span>
                            {phoneFormatter(item?.phone)}
                        </span>
                    </p>
                    <YesOrNotItemDescr
                        item={item?.developer?.fixAgentRules}
                        itemDescr={item?.developer?.fixAgentRules}
                        createContentModal={createContentModal}
                        title={'Условия закрепления'}
                    />
                    {/* <p>
                        <Link
                            to=''
                            className='btn btn-sm btn-secondary'
                            title='Условия закрепления'
                        >
                            <span>
                                Условия закрепления
                            </span>
                        </Link>
                    </p> */}
                </td>
                <td>
                    <ButtonGroup vertical={true} className='w-100'>
                        {item?.isFavorite ? (
                            <Button
                                size='sm'
                                variant='primary'
                                title='Убрать из избранного'
                                onClick={handleFavoriteClick}
                            >
                                <FontAwesomeIcon icon={['fas', 'heart']} />{' '}
                                Убрать из избранного
                            </Button>
                        ) : (
                            <Button
                                size='sm'
                                variant='outline-primary'
                                title='Добавить в избранное'
                                onClick={handleFavoriteClick}
                            >
                                <FontAwesomeIcon icon={['fas', 'heart']} />{' '}
                                Добавить в избранное
                            </Button>
                        )}
                    </ButtonGroup>
                </td>
            </tr>
            <OverlayingPopup onClose={onClose} isOpened={isModalOpen}>
                <div className='main_popup' style={{
                    width: '600px',
                    wordWrap: 'break-word',
                }}>
                    <div className='w-100 d-flex justify-content-end'>
                        <div>
                            <FontAwesomeIcon
                                icon={faTimesCircle}
                                style={{ fontSize: '17px', cursor: 'pointer' }}
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <p style={{
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                    }}
                    > <LinkifyText text={modalContent} /> </p>
                </div>
            </OverlayingPopup>
        </React.Fragment>
    );
};

export default DeveloperEstateListRow;
