import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';
import { IDeveloper, IListEstateDev } from '~/src/service/developer/developer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayingPopup from '../Common/ModalUI/OverlayingPopup';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { LinkifyText } from '../Common/LinkifyText';

const YesOrNotItemDescr = ({ item, itemDescr, createContentModal }) => {
    return (
        <>
            {
                item
                    ? itemDescr
                        ? <Button
                            variant='link'
                            size='sm'
                            onClick={() => createContentModal(itemDescr)}
                        >
                            Да
                        </Button >
                        : <>Да</>
                    : 'Нет'
            }
        </>
    );
};


const ListRow: FC<{ itemDevelop: IDeveloper, item: IListEstateDev }> = ({ itemDevelop, item }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const createContentModal = (content: string) => {
        setIsModalOpen(true);
        if (content) {
            setModalContent(content);
        } else {
            setModalContent('...');
        }
    };

    const onClose = () => {
        setIsModalOpen(false);
    };

    return (
        <React.Fragment>
            <tr>
                <td>{itemDevelop.id}</td>
                <td style={{
                    maxWidth: '200px'
                }}>
                    {
                        itemDevelop.siteLink
                            ? <a href={itemDevelop.siteLink} target='_blank' rel='noopener noreferrer'>
                                {itemDevelop?.title}
                            </a>
                            : itemDevelop?.title
                    }
                    {itemDevelop?.isCrucialPartner == 1 && (
                        <>
                            <br />
                            <Badge className='bg-success'>Ключевой партнер</Badge>
                        </>
                    )}
                    {
                        (itemDevelop?.projects?.length > 0 || itemDevelop?.isSuburban) &&
                        <>
                            <br />
                            <Badge className='bg-primary'>Подрядчик</Badge>
                        </>
                    }
                </td>
                <td>
                    {
                        item.siteUrl
                            ? <a href={item.siteUrl} target='_blank' rel='noopener noreferrer'>
                                {item?.title}
                            </a>
                            : item?.title
                    }
                    {
                        (item?.feeds?.length > 0) &&
                        <>
                            <br />
                            <Badge className='bg-success'>Фид</Badge>
                        </>
                    }
                </td>
                <td>
                    {
                        item?.reward
                            ? item?.rewardDescr
                                ? <Button
                                    variant='link'
                                    size='sm'
                                    onClick={() => createContentModal(item?.rewardDescr)}
                                >
                                    {item?.reward}
                                </Button>
                                : <>{item?.reward}</>
                            : ''
                    }
                </td>
                <td>
                    <YesOrNotItemDescr
                        item={item?.isCompanyShare}
                        itemDescr={item?.companyShareDescr}
                        createContentModal={createContentModal}
                    />
                </td>
                <td>
                    <YesOrNotItemDescr
                        item={item?.subsidizedRate}
                        itemDescr={item?.subsidizedRateDescr}
                        createContentModal={createContentModal}
                    />
                </td>
                <td>
                    <YesOrNotItemDescr
                        item={item?.installment}
                        itemDescr={item?.installmentDescr}
                        createContentModal={createContentModal}
                    />
                </td>
                <td>
                    <YesOrNotItemDescr
                        item={item?.buyWoInitialPay}
                        itemDescr={item?.buyWoInitialPayDescr}
                        createContentModal={createContentModal}
                    />
                </td>
                <td>
                    <YesOrNotItemDescr
                        item={item?.isRennovationFlats}
                        itemDescr={item?.rennovationFlatsDescr}
                        createContentModal={createContentModal}
                    />
                </td>
                <td>
                    {
                        itemDevelop?.fixAgentRules
                            ? <Button
                                variant='link'
                                size='sm'
                                onClick={() => createContentModal(itemDevelop?.fixAgentRules)}
                            >
                                Условия
                            </Button>
                            : 'Нет'
                    }
                </td>
                <td>
                    <YesOrNotItemDescr
                        item={itemDevelop?.isExchangeProgramm}
                        itemDescr={itemDevelop?.exchangeProgrammDescription}
                        createContentModal={createContentModal}
                    />
                </td>
                <td>
                    {
                        itemDevelop?.chatLink
                            ? <Link
                                to={itemDevelop?.chatLink}
                                className='btn btn-sm btn-success'
                                title='Чат'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Чат
                            </Link>
                            : 'Нет'
                    }
                </td>
                {/* <td>
                    {itemDevelop.contactPhone && (
                        <p>
                            <strong>Телефон основной:</strong>
                            <br />
                            <span>{phoneFormatter(itemDevelop.contactPhone)}</span>
                        </p>
                    )}
                    {itemDevelop.email && (
                        <p>
                            <strong>Email:</strong>
                            <br />
                            <span>{itemDevelop.email}</span>
                        </p>
                    )}
                    {itemDevelop.contacts?.map((contact) => {
                        return (
                            <React.Fragment key={contact.id}>
                                {contact.note && <p>{contact.note}</p>}
                                {contact.phone && (
                                    <p>{phoneFormatter(`${contact.phone}`)}</p>
                                )}
                                {contact.email && <p>{contact.email}</p>}
                                <br />
                            </React.Fragment>
                        );
                    })}
                </td> */}
                <td>
                    <ButtonGroup vertical>
                        <Link
                            to={`/developer/update/${itemDevelop.id}`}
                            className='btn btn-sm btn-warning'
                            title='Редактировать'

                        >
                            Редактировать
                        </Link>
                    </ButtonGroup>
                </td>
            </tr>
            <OverlayingPopup onClose={onClose} isOpened={isModalOpen}>
                <div className='main_popup' style={{
                    width: '600px',
                    wordWrap: 'break-word',
                }}>
                    <div className='w-100 d-flex justify-content-end'>
                        <div>
                            <FontAwesomeIcon
                                icon={faTimesCircle}
                                style={{ fontSize: '17px', cursor: 'pointer' }}
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <p style={{
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                    }}
                    > <LinkifyText text={modalContent} /> </p>
                </div>
            </OverlayingPopup>
        </React.Fragment>
    );
};

export default ListRow;
