import { useNavigate } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import { selectApp } from '~/src/store/appSlice';
import { getUserByPhone } from '~/src/service/user';
import UpdateContactView from './UpdateContactView';
import { Form as FinalForm } from 'react-final-form';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShowErrorsBadge from '../Common/ShowErrorBadge';
import UpdateAdditionalView from './UpdateAdditionalView';
import UpdateDescriptionView from './UpdateDescriptionView';
import { IHousingEstate } from '~/src/service/housing.estate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Navbar, Tab, Nav } from 'react-bootstrap';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import {
    update,
    deleteFile,
    selectHE,
    uploadPhoto,
} from '~/src/store/housingEstateSlice';
import UpdatePhotosView from '~/src/components/Common/Filestore/Photos/UpdatePhotosView';

export default function UpdateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [item, setItem] = useState<IHousingEstate>(null);
    // const [uploadFiles, setUploadFiles] = useState(null);
    const [progress, setProgress] = useState(0);
    const [holdSubmit, setHoldSubmit] = useState(false);
    const [preview, setPreview] = useState([]);

    const { item: data, itemPhotos } = useSelector(selectHE);
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        setItem(data);
    }, [data]);

    const onSubmit = (values: Record<string, any>) => {
        // const filesRecord = uploadFiles === null ? undefined : { 'photosUpload': uploadFiles };

        const cleanedValues = { ...values };
        delete cleanedValues.houses;

        dispatch(update(values.id, { ...cleanedValues }));

        // UPD:: удаляли поле этажей, для сокращение данных. Теперь механизм с добавлением домов убран, это пока закомментил
        // const cleanedValues = {
        //     ...values,
        //     houses: values.houses?.map((house) => {
        //         return {
        //             ...house,
        //             porches: house.porches?.map((porch) => {
        //                 delete porch.storeys;

        //                 return porch;
        //             }),
        //         };
        //     }),
        // };
    };

    const findUserByPhone = async (phone: number) => {
        const _res = await getUserByPhone(phone);

        return _res;
    };

    const onUploadProgress = (event) => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        setProgress(percentCompleted);
        if (percentCompleted === 100) {
            setPreview(null);
        }
    };

    const setUploadFiles = (compressedFiles: File[] | FileList) => {
        dispatch(
            uploadPhoto({
                files: compressedFiles,
                id: item.id,
                onUploadProgress,
            }),
        );
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
            {loading || item === null ? (
                <>Загрузка...</>
            ) : (
                <FinalForm
                    onSubmit={onSubmit}
                    initialValues={{
                        ...createInitialFormValues(item),
                        userUidName: item.owner?.fullname || '',
                        userUidPhone: item.owner?.phone || '',
                        addressName: item.addressDetail?.formattedAddress,
                    }}
                    mutators={{
                        // expect (field, value) args from the mutator
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        },
                        setValueRewardDescr: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value.trim());
                        },
                        setUserUidValue: async (
                            [field, value],
                            state,
                            { changeValue },
                        ) => {
                            changeValue(state, field, () => value);
                            if (value.length == 10) {
                                setHoldSubmit(true);
                                const founded = await findUserByPhone(value);

                                if (founded && founded.success) {
                                    changeValue(
                                        state,
                                        'userUid',
                                        () => founded.data.uid,
                                    );
                                    changeValue(
                                        state,
                                        'userUidName',
                                        () => founded.data.fullname,
                                    );
                                    // -- setDirectorName(founded.fullname);
                                }
                                // -- setHoldSubmit(false);
                                setHoldSubmit(false);
                            } else {
                                changeValue(state, 'userUid', () => null);
                                changeValue(state, 'userUidName', () => '');
                            }
                        },
                        ...arrayMutators,
                    }}
                    validate={() => {
                        const errors: any = {};
                        return errors;
                    }}
                    render={({ handleSubmit, submitting, valid, form }) => (
                        <form onSubmit={handleSubmit}>
                            <Navbar
                                className='justify-content-between'
                                bg='light'
                                expand='lg'
                                variant='light'
                            >
                                <ButtonGroup>
                                    <Button
                                        size='sm'
                                        onClick={() => navigate(-1)}
                                        disabled={submitting}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'fast-backward']}
                                        />{' '}
                                        Назад
                                    </Button>
                                    <Button
                                        size='sm'
                                        type='submit'
                                        className='btn btn-sm btn-success'
                                        disabled={submitting || !valid || holdSubmit}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'save']}
                                        />{' '}
                                        Сохранить
                                    </Button>
                                </ButtonGroup>
                            </Navbar>
                            <Tab.Container defaultActiveKey='description'>
                                <Nav justify variant='tabs'>
                                    <Nav.Item>
                                        <Nav.Link eventKey='description'>
                                            <span>Описание </span>
                                            <ShowErrorsBadge fields={[]} />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='photos'>
                                            <span>Фото </span>
                                            <ShowErrorsBadge fields={[]} />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='additional'>
                                            <span>Параметры </span>
                                            <ShowErrorsBadge fields={[]} />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='contact'>
                                            Контакты
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content className='container-fluid mt-2 p-0'>
                                    <Tab.Pane eventKey='description'>
                                        <UpdateDescriptionView />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='photos'>
                                        <UpdatePhotosView
                                            filesFieldName='photosUpload'
                                            photos={itemPhotos}
                                            preview={preview}
                                            setPreview={setPreview}
                                            setUploadFiles={setUploadFiles}
                                            onDelete={(name) =>
                                                dispatch(
                                                    deleteFile(item.id, name),
                                                )
                                            }
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='additional'>
                                        <UpdateAdditionalView />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='contact'>
                                        <UpdateContactView />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={submitting || !valid || holdSubmit}
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </form>
                    )}
                />
            )}
        </Suspense>
    );
}
