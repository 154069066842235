import {
    combineHousingLivingArea,
    selectlivingArea,
    unloadAllLivingArea,
} from '~/src/store/livingAreaSlice';
import {
    selectChangePersonOnHousing,
    unloadAll,
} from '~/src/store/changePersonOnHousingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const UnloadingHousingArea = () => {
    const dispatch = useDispatch();

    const { allHousingEstate } = useSelector(selectChangePersonOnHousing);
    const { allLivingArea } = useSelector(selectlivingArea);
    useEffect(() => {
        if (allLivingArea === null) {
            dispatch(unloadAllLivingArea());
        }
    }, [dispatch, allLivingArea]);

    useEffect(() => {
        if (allHousingEstate === null) {
            dispatch(unloadAll());
        }
    }, [dispatch, allHousingEstate]);

    useEffect(() => {
        if (allHousingEstate?.length && allLivingArea?.length) {
            dispatch(combineHousingLivingArea(allHousingEstate, allLivingArea));
        }
    }, [dispatch, allHousingEstate, allLivingArea]);

    return <></>;
};

export default UnloadingHousingArea;
