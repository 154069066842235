import Api from '~/src/helpers/http-common';
import { IResponseListData } from '../interfaces/common';
import { IDEFModel } from '../store/developerEstateFeed';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IDEFModel>> => {
    return await Api.get(
        `/housing-estate/feed/all?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const createDeveloperEstateFeed = async (
    data: IDeveloperEstateCreacteFeed,
): Promise<IDeveloperEstateCreacteFeed | any> => {
    return (await Api.post<IDeveloperEstateCreacteFeed>(
        '/housing-estate/feed',
        data,
    )) as IDeveloperEstateCreacteFeed;
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/housing-estate/feed/${id}`);
};

export const read = async (id: number): Promise<IDEFModel> => {
    return await Api.get(`/housing-estate/feed/${id}`);
};

export const update = async (
    id: string | number,
    data: IDeveloperEstateCreacteFeed,
    files?: Record<string, FileList>,
): Promise<IDeveloperEstateCreacteFeed> => {
    return await Api.put<IDeveloperEstateCreacteFeed>(
        `/housing-estate/feed/${id}`,
        data,
    );
};

export interface IDeveloperEstateCreacteFeed {
    title?: string;
    description?: string;
    housingEstateId?: number;
    developerId: number;
    fileUrl: string;
}
