import { useNavigate } from 'react-router-dom';
import InputField from '../Common/Form/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { create, update, selectDeveloper } from '~/src/store/developer/developerSlice';
import {
    required,
} from '~/src/helpers/validators';
import { Button, ButtonGroup, Container, Form, FormGroup, Navbar } from 'react-bootstrap';
import arrayMutators from 'final-form-arrays';
import { selectChangePersonOnHousing } from '~/src/store/changePersonOnHousingSlice';
import Select from 'react-select';
import { useQuery } from '~/src/helpers/hooks';
import { createDeveloperEstateFeed } from '~/src/store/developerEstateFeed';

interface IDeveloperEstateCreacteFeedForm {
    title?: string;
    description?: string;
    housingEstateId?: {
        value: number;
        label: string;
    };
    developerId: {
        value: number;
        label: string;
    };
    fileUrl: string;
}

const CreateForm = () => {

    const query = useQuery();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { unloaded } = useSelector(selectDeveloper);
    const { allHousingEstate: housingEstates } = useSelector(selectChangePersonOnHousing);
    const initDeveloperId = query.get('developerId');
    const initHousingEstateId = query.get('housingEstateId');

    const developers = Array.from(new Map(unloaded.map(item => [item.id, { value: item.id, label: item.title }])).values());

    const onSubmit = (values: IDeveloperEstateCreacteFeedForm) => {
        dispatch(createDeveloperEstateFeed({
            ...values,
            developerId: values.developerId.value,
            housingEstateId: values.housingEstateId ? values.housingEstateId.value : null
        }));
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={{
                developerId: developers?.find(dev => dev.value === Number(initDeveloperId)),
                housingEstateId: housingEstates?.find(estate => estate.value === Number(initHousingEstateId)),
            }}
            mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
                ...arrayMutators,
            }}
            validate={(values) => {
                const errors: any = {};
                if (!values.developerId || values.developerId == null) {
                    errors.developerId = 'Выберите застройщика';
                }
                return errors;
            }}
            render={({ handleSubmit, submitting, valid, form }) => (
                <form onSubmit={handleSubmit}>
                    <Navbar
                        className='justify-content-between'
                        bg='light'
                        expand='lg'
                        variant='light'
                    >
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() => navigate(-1)}
                                disabled={submitting}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'fast-backward']}
                                />{' '}
                                Назад
                            </Button>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={submitting || !valid}
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Navbar>
                    <Container fluid>
                        <Field name='developerId'>
                            {({ input, meta }) => (
                                <Form.Group className='mb-2'>
                                    <Form.Label>
                                        <>Застройщик</>
                                    </Form.Label>
                                    <Select
                                        {...input}
                                        placeholder='Застройщик'
                                        onChange={(e) =>
                                            form.mutators.setValue(
                                                'developerId',
                                                e,
                                            )
                                        }
                                        options={developers}
                                    />
                                    {((meta.error && meta.touched) || !form.getState().values['developerId']) && (
                                        <span
                                            style={{
                                                color: 'red',
                                                fontSize: '14px',
                                            }}
                                        >
                                            {meta.error}
                                        </span>
                                    )}
                                </Form.Group>
                            )}
                        </Field>
                        <InputField
                            name='fileUrl'
                            label='Ссылка на фид'
                            validators={required}
                        />
                        <InputField
                            name='title'
                            label='Название'
                            validators={required}
                        />
                        <Field name='housingEstateId'>
                            {({ input }) => (
                                <Form.Group className='mb-2'>
                                    <Form.Label>
                                        <>Жилой комплекс</>
                                    </Form.Label>
                                    <Select
                                        {...input}
                                        placeholder='Выберите ЖК'
                                        onChange={(e) =>
                                            form.mutators.setValue(
                                                'housingEstateId',
                                                e,
                                            )
                                        }
                                        options={housingEstates}
                                    />
                                </Form.Group>
                            )}
                        </Field>
                        <Field name='description'>
                            {({ input, meta }) => (
                                <Form.Group className='mb-3'>
                                    <Form.Label>Описание</Form.Label>
                                    <Form.Control
                                        {...input}
                                        as='textarea'
                                        rows={5}
                                        size='sm'
                                        isInvalid={
                                            meta.error || meta.submitError
                                        }
                                        placeholder='Описание'
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Field>
                    </Container>
                    <Button
                        size='sm'
                        type='submit'
                        className='btn btn-sm btn-success'
                        disabled={submitting || !valid}
                    >
                        <FontAwesomeIcon icon={['fas', 'save']} /> Сохранить
                    </Button>
                </form>
            )}
        />
    );
};

export default CreateForm;
