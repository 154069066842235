import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeveloperEstateFeedListView from '~/src/components/DeveloperEstateFeed';
import { useQuery } from '~/src/helpers/hooks';
import { setActiveMenuKey, setHandleScrollPosition } from '~/src/store/appSlice';
import { all } from '~/src/store/developerEstateFeed';

const DeveloperEstateFeedPage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenuKey('DEF'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    return (
        <DeveloperEstateFeedListView />
    );
};

export default DeveloperEstateFeedPage;
