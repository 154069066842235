import phoneFormatter from '~/src/helpers/phoneFormatter';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import React, { FC, useState } from 'react';
import dateFormat from 'dateformat';
import { IDEFModel, remove } from '~/src/store/developerEstateFeed';
import { Link } from 'react-router-dom';
import Confirm from '../Common/ModalUI/AlertModals/Confirm';
import { useDispatch } from 'react-redux';

const DeveloperEstateFeedListRow: FC<{ item: IDEFModel }> = ({ item }) => {
    const dispatch = useDispatch();

    const [deleteConfirmShow, setDeleteConfirmShow] = useState(false);

    const copyPresentationLink = async () => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(item.fileUrl);
        }
    };

    const handleDeleteClick = () => {
        dispatch(remove(item?.id));
        setDeleteConfirmShow(false);
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <p>{item.id}</p>
                </td>
                <td>
                    <strong>{item?.title}</strong>
                    {item.createdAt && (
                        <p style={{
                            fontSize: '12px'
                        }}>
                            Создано:{' '}
                            {dateFormat(item.createdAt, 'dd.mm.yyyy hh:MM:ss')}
                        </p>
                    )}
                    {item.updatedAt && (
                        <p style={{
                            fontSize: '12px'
                        }}>
                            Обновлен:{' '}
                            {dateFormat(item.updatedAt, 'dd.mm.yyyy hh:MM:ss')}
                        </p>
                    )}
                </td>
                <td>
                    {
                        item?.developerId &&
                        <p>
                            <Link to={`/developer/update/${item.developerId}`}><strong>Застройщик</strong></Link>
                        </p>
                    }
                    {
                        item?.housingEstateId &&
                        <p>
                            <Link to={`/housing-estate/update/${item.housingEstateId}`}><strong>ЖК</strong></Link>
                        </p>
                    }
                </td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant='secondary'
                            size='sm'
                        >
                            Ссылка на фид
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                href={item?.fileUrl}
                                target='_blank'
                            >
                                Смотреть
                            </Dropdown.Item>
                            <Dropdown.Item onClick={copyPresentationLink}>
                                Скопировать ссылку
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
                <td style={{
                    fontSize: '12px'
                }}>
                    {
                        item?.parsed.length > 0 &&
                        <>
                            <p>
                                {
                                    <span><b>Количество записей:</b> {item.parsed[0].total}</span>
                                }
                            </p>
                            <p>
                                {
                                    item.parsed[0].startAt &&
                                    <span>Начало: {dateFormat(item.parsed[0].startAt, 'dd.mm.yyyy hh:MM:ss')}</span>
                                }
                            </p>
                            <p>
                                {
                                    item.parsed[0].endAt &&
                                    <span>Конец: {dateFormat(item.parsed[0].endAt, 'dd.mm.yyyy hh:MM:ss')}</span>
                                }
                            </p>
                            <p>
                                {
                                    item.parsed[0].error &&
                                    <span style={{
                                        color: 'red',
                                        fontSize: '11px'
                                    }}>Произошла ошибка при парсе</span>
                                }
                            </p>
                        </>
                    }
                </td>
                <td>
                    <ButtonGroup vertical>
                        <Link
                            to={`/developer-estate-feed/update/${item.id}`}
                            className='btn btn-sm btn-warning'
                            title='Редактировать'
                        >
                            Редактировать
                        </Link>
                        <Button
                            type='button'
                            size='sm'
                            variant='danger'
                            onClick={() => setDeleteConfirmShow(true)}
                        >
                            Удалить
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
            <Confirm
                text='Удалить? Это действие безвозвратно!'
                onClose={() => setDeleteConfirmShow(false)}
                isOpened={deleteConfirmShow}
                primaryButtonOnClick={handleDeleteClick}
                secondaryButtonOnClick={() => setDeleteConfirmShow(false)}
            />
        </React.Fragment >
    );
};

export default DeveloperEstateFeedListRow;
